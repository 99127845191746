import { createApp } from 'vue'
import App from './App.vue'
import mitt from 'mitt'
import './registerServiceWorker'
import createRouter from './router'
import createStore from './store'
import filters from './filters'
import directives from './directives'
import VueScrollTo from 'vue-scrollto'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createI18nPlugin, useI18nPlugin } from '@unify/vuex-i18n'
import { logger } from '@/helpers/logger'
import VueTippy from 'vue-tippy'
import VueLazyLoad from 'vue-lazyload'
import VueNouislider from 'vue3-nouislider'
import { createHead } from '@vueuse/head'
import VueWaypoint from 'vue-waypoint'
import { vImg } from '@/plugins/vImg'
import VHtml from '@/components/global/HtmlFragment'

const app = createApp(App)

// make global filters available to all components through globalProperties
app.config.globalProperties.$filters = filters

// vImg
app.use(vImg)

// tippy
app.use(VueTippy, {
  directive: 'tippy',
  arrow: true,
  animation: 'shift-toward',
  delay: [0, 100],
  defaultProps: {
    allowHTML: true
  }
})

// v-lazy
const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i)
const useObserver = !isSamsungBrowser

app.use(VueLazyLoad, {
  observer: useObserver,
  attempt: 3,
  throttleWait: 50,
  adapter: {
    error (listener) {
      listener.el.parentNode.classList.add('ll-error')
    },
    loaded (listener) {
      listener.el.parentNode.classList.add('ll-loaded')
    }
  },
  observerOptions: {
    rootMargin: '200px',
    threshold: 0
  }
})

// nouislider
app.use(VueNouislider)

// waypoint
app.use(VueWaypoint)

// register directives
app.directive('scroll-to', VueScrollTo)
app.directive('wrap', directives.wrap)
app.directive('resize', directives.resize)
app.directive('stick-in-parent', directives.stickInParent)

// make global emitter (event bus)
const emitter = mitt()
app.config.globalProperties.emitter = emitter

// provide axios
app.use(VueAxios, { $axios: axios })
app.provide('$axios', app.config.globalProperties.$axios)

// metadata support
const head = createHead()
app.use(head)

// store
const store = createStore(app.config.globalProperties.$axios)
app.use(store)

// i18n
app.use(createI18nPlugin(store, {
  moduleName: 'i18n',
  onTranslationNotFound (locale, key) {
    logger(`i18n :: Key '${key}' not found for locale '${locale}'`)
  }
}))

const i18n = useI18nPlugin()

// Add translations and set language
// set fallback language (used if there is no string in current language file)
i18n.add('en', require('@/i18n/en.json')).then(i18n.fallback('en'))
i18n.add('pl', require('@/i18n/pl.json'))
i18n.add('jp', require('@/i18n/jp.json'))
i18n.add('kr', require('@/i18n/kr.json'))
i18n.add('pt', require('@/i18n/pt.json'))
i18n.add('tr', require('@/i18n/tr.json'))
i18n.add('ru', require('@/i18n/ru.json'))
i18n.add('zhs', require('@/i18n/zhs.json'))
i18n.add('zht', require('@/i18n/zht.json'))
i18n.add('ua', require('@/i18n/ua.json'))

// router
const router = createRouter(store, i18n, app.config.globalProperties.$axios)
app.config.globalProperties.$route = router
app.use(router)

// VHtml component
app.component('v-html', VHtml)

// Google Tag Manager
if (process.env.VUE_APP_CHINA_VERSION !== '' && process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID && process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID !== '') {
  // Define dataLayer and the gtag function.
  const dataLayer = window.dataLayer || []
  function gtag () { dataLayer.push(arguments) }

  // Set default consent to 'denied' as a placeholder
  // Determine actual values based on your own requirements
  gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'granted'
  })

  logger('GTM loaded: ' + process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID)
  const tag = process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID
  let gtm = document.createElement('noscript')
  const insertApp = document.getElementById('app')
  gtm.innerHTML = `<iframe src="//www.googletagmanager.com/ns.html?id=${tag}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
  document.body.insertBefore(gtm, insertApp)
  gtm = document.createElement('script')
  gtm.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${tag}');`
  document.head.appendChild(gtm)
}

logger('Final app: ', app)

// Wait for the router to be ready before mounting the app
// router.isReady().then(() => app.mount('#app'))

// fix for "Does not use passive listeners to improve scrolling performance"
/* eslint-disable no-undef */
jQuery.event.special.touchstart = {
  setup: function (_, ns, handle) {
    this.addEventListener('touchstart', handle, { passive: !ns.includes('noPreventDefault') })
  }
}

jQuery.event.special.touchmove = {
  setup: function (_, ns, handle) {
    this.addEventListener('touchmove', handle, { passive: !ns.includes('noPreventDefault') })
  }
}

jQuery.event.special.wheel = {
  setup: function (_, ns, handle) {
    this.addEventListener('wheel', handle, { passive: true })
  }
}

jQuery.event.special.mousewheel = {
  setup: function (_, ns, handle) {
    this.addEventListener('mousewheel', handle, { passive: true })
  }
}
/* eslint-enable no-undef */

app.mount('#app')
