import { createRouter, createWebHistory } from 'vue-router'
import { staticRoutes } from './staticRoutes'
import { getExternalRoute } from './getExternalRoute'
import { logger } from '@/helpers/logger'

export default function (store, i18n, axios) {
  const router = createRouter({
    history: createWebHistory(),
    routes: staticRoutes,
    // restore default browser behaviours - remember position and scroll to hash
    scrollBehavior (to, from, savedPosition) {
      let scrollPosition = { x: 0, y: 0, behavior: 'smooth' }
      if (savedPosition) {
        scrollPosition = savedPosition
      } else if (to.hash) {
        scrollPosition = {
          selector: to.hash
        }
      } else {
        window.scrollTo(0, 0)
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(scrollPosition)
        }, 500)
      })
    }
  })

  // check target url and add new route if needed
  router.beforeEach(function (to, from, next) {
    if (from.path !== to.path || JSON.stringify(from.query) !== JSON.stringify(to.query)) {
      const encodedPath = encodeURI(to.path)
      const matchedRoutes = router.resolve(to.path.replace(/ /g, '%20')).matched
      const matchedEncodedRoutes = router.resolve(encodedPath).matched
      const matchedRoutesLength = matchedRoutes.length
      const matchedEncodedRoutesLength = matchedEncodedRoutes.length

      document.body.classList.remove('noscroll')
      store.dispatch('setHeaderText', '')
      store.dispatch('turnOnFooter')

      const urlLang = to.path.split('/')[1]

      if (i18n.localeExists(urlLang)) {
        if (i18n.locale() !== urlLang) {
          store.dispatch('negotiateLanguage', urlLang)
          i18n.set(urlLang)
        }
      } else {
        router.push({
          path: '/en/404'
        })
      }

      store.dispatch('turnOnPreloader')

      if ((to.path !== '/') && (to.path !== '')) {
        // if no matched routing entry is present make api request to get one
        // all routes should be added without spaces
        if (matchedRoutesLength) {
          store.dispatch('setTargetRouteName', matchedRoutes[0].meta.type)
          store.dispatch('GTMHelperPushPageLoadEvent', to.path)
          store.dispatch('setEditLink', '')
          next()
        } else if (matchedEncodedRoutesLength) {
          router.push({
            path: encodedPath
          }).catch((error) => {
            logger('Router route push error: ' + encodedPath, error)
          })
        } else {
          getExternalRoute(from, to, urlLang, router, store, axios)
        }
      } else {
        next()
      }
    } else {
      next(false)
    }
  })

  return router
}
