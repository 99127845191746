<template>
  <div id="app-wrapper" v-resize.debounce="windowResize" :class="appClasses()">
    <app-header v-if="showHeader"/>
    <div id="main-content" class="main-content">
      <app-main/>
      <component v-if="showPreloader" :is="'Preloader'"/>
      <div id="ctld" v-else></div>
    </div>
    <app-footer v-if="showFooter && showCommonElements"/>
    <app-sidebar-left v-if="showCommonElements"/>
    <app-sidebar-right v-if="showCommonElements"/>
    <app-newsletter-popup></app-newsletter-popup>
  </div>
</template>

<script>
import { logger } from '@/helpers/logger.js'
import Header from '@/components/layout/Header.vue'
import Main from '@/components/layout/Main.vue'
import Footer from '@/components/layout/Footer.vue'
import Preloader from '@/components/common/Preloader.vue'
import SidebarLeft from '@/components/layout/SidebarLeft.vue'
import SidebarRight from '@/components/layout/SidebarRight.vue'
import NewsletterPopup from '@/components/popups/NewsletterPopup.vue'
import Cookies from 'js-cookie'
import { useStore } from 'vuex'
import { useI18nPlugin } from '@unify/vuex-i18n'

// find first parent with tagName [tagname]
function findParent (tagName, el) {
  while (el) {
    if ((el.nodeName || el.tagName).toLowerCase() === tagName.toLowerCase()) {
      return el
    }
    el = el.parentNode
  }
  return null
}

export default {
  name: 'app',
  setup () {
    const urlLang = window.location.pathname.split('/')[1]
    if (urlLang) {
      const store = useStore()
      const i18n = useI18nPlugin()

      store.dispatch('negotiateLanguage', urlLang)
      i18n.set(urlLang)
    }
  },
  data () {
    return {
      firstContentLoaded: false
    }
  },
  computed: {
    showPreloader () {
      return this.$store.getters.getPreloaderState
    },
    showFooter () {
      const targetComponent = this.$store.getters.getTargetRouteName

      if (targetComponent !== null && (targetComponent === 'newsletter' || targetComponent === 'games')) {
        return false
      }

      return this.$store.getters.getFooterState
    },
    showHeader () {
      const targetComponent = this.$store.getters.getTargetRouteName
      const preloaderIsPresent = this.$store.getters.getPreloaderState

      if (targetComponent !== null) {
        if (targetComponent === 'superArticle') {
          return !preloaderIsPresent
        }
      }

      return true
    },
    showCommonElements () {
      const targetComponent = this.$store.getters.getTargetRouteName

      return this.firstContentLoaded && targetComponent !== null && targetComponent !== 'superArticle'
    }
  },
  methods: {
    appClasses () {
      if (this.$i18n.locale()) {
        return 'lang-' + this.$i18n.locale()
      } else {
        return ''
      }
    },
    contextMenu (event) {
      // disable context menu for anonymous
      const element = event.target.tagName

      if (element === 'IMG' ||
        event.target.classList.contains('background-image') ||
        (event.target.getAttribute('style') && event.target.getAttribute('style').indexOf('background-image') !== -1)
      ) {
        const uat = Cookies.get('SESS_uat')
        if (uat === undefined) {
          event.preventDefault()
          return false
        }
      }
    },
    windowSize: function () {
      this.$store.dispatch('setWindowWidth', document.documentElement.clientWidth)
      this.$store.dispatch('setWindowHeight', window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)
    },
    windowResize: function () {
      this.windowSize()
    },
    getViewportHeight () {
      const a = document.documentElement.clientHeight
      const b = window.innerHeight
      return a < b ? b : a
    },
    getViewportScroll () {
      if (typeof window.scrollY !== 'undefined') {
        return window.scrollY
      }
      if (typeof pageYOffset !== 'undefined') {
        return pageYOffset
      }
      let doc = document.documentElement
      doc = doc.clientHeight ? doc : document.body
      return doc.scrollTop
    },
    // this function is no longer in use
    doMainParallax () {
      if (!($('body').hasClass('no-parallax'))) {
        const imageHeight = 3432 /* static/img/main-bg.png */
        const app = $('#app-wrapper')
        const appHeight = app.outerHeight()
        const vHeight = this.getViewportHeight()
        const scrollOffset = this.getViewportScroll()
        const yPos = ((appHeight - imageHeight) * (scrollOffset / (appHeight - vHeight)))
        const imageBgPosition = '50%' + yPos + 'px'

        if (appHeight > imageHeight) {
          app.css('background-position', imageBgPosition)
        }
      }
    },
    keyDownEvent (event) {
      // If ESC was pressed...
      if (event.which === 27) {
        this.emitter.emit('ESCPressed', event)
      }
      // If up arrow was pressed...
      if (event.which === 38) {
        this.emitter.emit('upArrowPressed', event)
      }
      // If down arrow was pressed...
      if (event.which === 40) {
        this.emitter.emit('downArrowPressed', event)
      }
    },
    textCopyEvent () {
      let text = window.getSelection().toString()

      if (text.length > 1360) {
        text = text.substr(0, 1360) + '...'
      }

      // push GAEvent
      this.$store.dispatch('GTMHelperPushGAEvent', {
        category: 'copyingText',
        action: 'copy',
        label: text
      })
    },
    clickEvent (event) {
      // check if target or its parent is <a> tag
      const aElement = findParent('a', event.target || event.srcElement)

      if (aElement) {
        // get href from <a>
        const href = aElement.getAttribute('href')
        const target = aElement.getAttribute('target')
        const paragraphUUID = aElement.getAttribute('data-paragraph')

        // process only absolute links
        if (href && href.startsWith('http')) {
          // ignore links with class containing "popup" word
          const continueProcessing = aElement.className.indexOf('popup') < 0 && target !== '_blank'

          if (continueProcessing) {
            // split href to parts
            const url = new URL(href)
            const hrefPath = url.pathname
            const hrefDomain = url.host

            // tests for domain checking
            // current domain
            const isCurrentDomain = window.location.host === hrefDomain
            let isProductionFrontendDomain = false

            // production frontend domain
            const productionFrontendDomain = process.env.VUE_APP_PRODUCTION_FRONTEND_DOMAIN
            if (productionFrontendDomain) {
              isProductionFrontendDomain = productionFrontendDomain === hrefDomain
            }

            // if it's internal link with absolute path
            if (isCurrentDomain || isProductionFrontendDomain) {
              // prevent default behaviour and push it to Vue router
              event.preventDefault()

              logger('Internal url with absolute path was pushed to router', href)

              this.$router.push({
                path: hrefPath
              })
            } else {
              // it is external url
              // push GAEvent
              this.$store.dispatch('GTMHelperPushGAEvent', {
                category: 'externalLinkClick',
                action: 'click',
                label: href
              })
            }
          }
        }

        if (paragraphUUID && paragraphUUID !== '') {
          event.stopPropagation()
          event.preventDefault()
          this.emitter.emit('AsideOpen', paragraphUUID)
        }
      }
    },
    preloaderOff () {
      this.firstContentLoaded = true
      document.removeEventListener('preloaderOff', this.preloaderOff)
    }
  },
  created: function () {
    const vm = this

    // Reset logo color
    vm.$store.dispatch('turnOffAlternativeLogo')

    // Preconnect backend
    const backendLink = document.createElement('link')

    backendLink.setAttribute('href', process.env.VUE_APP_ENDPOINT_URL)
    backendLink.setAttribute('rel', 'preconnect')
    document.head.appendChild(backendLink)

    setTimeout(function () {
      vm.windowSize()
    }, 100)
    this.$store.dispatch('setGTMHelper')
    window.addEventListener('keydown', this.keyDownEvent)
    window.addEventListener('copy', this.textCopyEvent)
    document.addEventListener('click', this.clickEvent)
    document.addEventListener('preloaderOff', this.preloaderOff)
  },
  mounted: function () {
    // $(window).on('scroll', this.doMainParallax)
    this.$nextTick(function () {
      document.addEventListener('contextmenu', this.contextMenu)
    })
  },
  beforeUnmount () {
    // $(window).off('scroll', this.doMainParallax)
    window.removeEventListener('keydown', this.keyDown)
    window.removeEventListener('copy', this.textCopyEvent)
    document.removeEventListener('click', this.clickEvent)
    document.removeEventListener('contextmenu', this.contextMenu)
  },
  components: {
    appHeader: Header,
    appMain: Main,
    appFooter: Footer,
    appSidebarLeft: SidebarLeft,
    appSidebarRight: SidebarRight,
    appNewsletterPopup: NewsletterPopup,
    Preloader
  }
}
</script>

<style lang="scss">
  @import "scss/app.scss";
</style>
