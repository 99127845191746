<template>
  <div id="language-switch" class="lang-switch-frontpage">
    <custom-select :options="langLabels.labels"
                   :onChange="selectChange"
                   v-model="selectedLanguage"
                   :value="selectedLanguage"/>
  </div>
</template>

<script>
import CustomSelect from '@/components/forms/CustomSelect.vue'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev],
  data () {
    return {
      langValues: ['pl', 'en', 'ua', 'jp', 'kr', 'zhs', 'zht', 'ru'],
      selectedLanguage: this.$t('language.' + this.$i18n.locale()),
      externalChange: true
    }
  },
  methods: {
    selectChange () {
      this.$router.push('/' + this.langValues[this.langLabels.labels.indexOf(this.selectedLanguage)])
    }
  },
  components: {
    CustomSelect
  },
  computed: {
    currentLanguage: function () {
      const lang = this.$store.getters.getLanguage

      this.selectedLanguage = this.$t('language.' + lang) // eslint-disable-line vue/no-side-effects-in-computed-properties
      this.externalChange = true // eslint-disable-line vue/no-side-effects-in-computed-properties
      return this.$store.getters.getLanguage
    },
    langLabels: function () {
      return {
        lang: this.currentLanguage,
        labels: [
          this.$t('language.pl'),
          this.$t('language.en'),
          this.$t('language.ua'),
          this.$t('language.jp'),
          this.$t('language.kr'),
          this.$t('language.zhs'),
          this.$t('language.zht'),
          this.$t('language.ru')
        ]
      }
    }
  }
}
</script>
