<template>
  <div class="search-bar">
    <div class="close">
      <a @click.prevent="disableSearchBar" @keyup.enter="disableSearchBar" href="#">
        {{ $t('search.close') }}
      </a>
    </div>
    <label for="search-input" class="label">{{ $t('search.label') }}</label>
    <div class="input">
      <input class="search-text"
             @keyup="inputChange"
             @keydown="checkKey"
             @focus="searchInputFocus"
             v-model="searchText"
             type="text"
             name="search-input"
             id="search-input"
             :disabled="inputBlocked">
      <button type="button" @click="makeSearch">{{ $t('search.button.text') }}</button>
    </div>
    <div class="autocomplete">
      <ul class="autocomplete-menu">
        <template v-for="(link, index) in autocompleteData">
          <li v-if="link.url" :key="index">
            <router-link :to="String(link.url).trim()"
                         class="suggestion"
                         exact
                         @click="linkClick(String(link.url).trim())">
                <span class="title">{{ link.title }}</span>
                <span class="subtitle" v-if="link.subtitle">{{ link.subtitle }}</span>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { logger } from '@/helpers/logger.js'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev],
  props: {
    // delay before sending autocomplete request [ms]
    delay: {
      type: Number,
      default: 1000
    },
    // minimal input characters to send search request
    minimalCharacters: {
      type: Number,
      default: 2
    },
    // minimal input characters to send autocomplete request
    minimalCharactersAutocomplete: {
      type: Number,
      default: 3
    },
    // turn off autocomplete
    turnOffAutocomplete: {
      type: Boolean,
      default: false
    },
    // start SearchText
    startSearchText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // current search text from input field
      searchText: '',
      // encoded search text
      encodedSearchText: '',
      // store data returned from autocompleate endpoint
      autocompleteData: {},
      // if it's true prevent user from changing input
      inputBlocked: false,
      // store reference to active timeout
      timeoutID: '',
      // store actual suggestion position
      actualSuggestionPosition: -1,
      timeouts: []
    }
  },
  methods: {
    checkKey (event) {
      // check if pressed key is enter
      if (event.which === 13 || event.keyCode === 13) {
        this.makeSearch()
        return true
      }
    },
    makeSearch () {
      // check if search text is long enough
      if (this.searchText && (this.searchText.length >= this.$props.minimalCharacters)) {
        const language = this.$i18n.locale()
        // clear translations
        // remove if search patches will be supported by routing endpoint
        this.$store.dispatch('updateTranslations', {})
        // redirect to search page (all routes should be added without spaces)
        const searchText = this.searchText.replace(/ /g, '%20').replace(/^\/+|\/+$/g, '')
        this.$router.push({
          path: `/${language}/${this.$t('search.slug')}/${searchText}`
        })
        return false
      }
    },
    inputChange () {
      if (!this.$props.turnOffAutocomplete) {
        if (this.timeoutID !== '') {
          // clear old timeout because of change of search string
          clearTimeout(this.timeoutID)
        }
        // check conditions to make autocomplete
        if (!this.inputBlocked && this.searchText.length >= this.$props.minimalCharactersAutocomplete) {
          const vm = this
          this.timeoutID = setTimeout(function () {
            // block input
            vm.inputBlocked = true
            // clear timeoutID
            vm.timeoutID = ''
            // fetch autocomplete data
            vm.autocompleteFetch(vm.encodedSearchText)
          }, this.$props.delay)
        }
      }
    },
    autocompleteFetch (search) {
      // fetch data
      let url = ''
      url = process.env.VUE_APP_ENDPOINT_URL + this.$i18n.locale() + '/api/search/autocomplete/elastic_search?q=' + search
      let options = {}
      if (process.env.VUE_APP_ENDPOINT_AUTHORIZATION_HEADER !== '') {
        const authHeader = 'Basic ' + btoa(process.env.VUE_APP_ENDPOINT_AUTHORIZATION_HEADER)
        options = {
          url,
          method: 'GET',
          headers: {
            Authorization: authHeader
          }
        }
      } else {
        options = {
          url,
          method: 'GET'
        }
      }
      logger('Autocomplete endpoint:', options)
      this.$axios.request(options).then(response => {
        if (response.data.status === 'error') {
          logger('Autocomplete Communication error', response)
        }
        this.autocompleteData = response.data
        logger('deserialized autocomplete data:', response.data)
        // unblock input
        this.inputBlocked = false
        const timeout = setTimeout(function () {
          const elem = $('header .search-bar input')
          if (elem && elem.length) {
            elem[0].focus()
          }
        }, 100)
        this.timeouts.push(timeout)
      }, response => {
        logger('Autocomplete Communication error', response)
        // unblock input
        this.inputBlocked = false
      })
    },
    upArrowPressedSearchBar () {
      if (this.autocompleteData) {
        if (this.actualSuggestionPosition !== -1) {
          this.actualSuggestionPosition = this.actualSuggestionPosition - 1
        }
      }
    },
    downArrowPressedSearchBar () {
      if (this.autocompleteData) {
        if (this.actualSuggestionPosition !== this.autocompleteData.length - 1) {
          this.actualSuggestionPosition = this.actualSuggestionPosition + 1
        }
      }
    },
    ESCPressedSearchBar () {
      this.$store.dispatch('turnOffSearchBar')
      this.focusSearchToggle()
    },
    linkClick (url) {
      this.$store.dispatch('GTMHelperPushGAEvent', {
        category: 'searchSuggestion',
        action: 'click',
        label: url
      })
    },
    searchInputFocus () {
      this.$store.dispatch('GTMHelperPushGAEvent', {
        category: 'searchInput',
        action: 'focus'
      })
    },
    disableSearchBar: function () {
      document.body.classList.remove('search-open')
      this.$store.dispatch('turnOffSearchBar')
      this.focusSearchToggle()
    },
    focusSearchToggle () {
      if (document.querySelector('header #search-toggle')) {
        document.querySelector('header #search-toggle').focus()
      }
    }
  },
  mounted: function () {
    if (this.$props.startSearchText) {
      this.searchText = this.$props.startSearchText
      this.encodedSearchText = this.$props.startSearchText
    }
    this.emitter.on('upArrowPressed', this.upArrowPressedSearchBar)
    this.emitter.on('downArrowPressed', this.downArrowPressedSearchBar)
    this.emitter.on('ESCPressed', this.ESCPressedSearchBar)
  },
  beforeUnmount () {
    this.emitter.off('upArrowPressed', this.upArrowPressedSearchBar)
    this.emitter.off('downArrowPressed', this.downArrowPressedSearchBar)
    this.emitter.off('ESCPressed', this.ESCPressedSearchBar)
    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i])
    }
    clearTimeout(this.timeoutID)
  },
  watch: {
    '$route' () {
      this.$store.dispatch('turnOffSearchBar')
      if (this.$props.startSearchText) {
        this.searchText = this.$props.startSearchText.replace(/\+/g, ' ').replace(/%20/g, ' ').replace(/%2B/g, ' ')
      }
    },
    searchText: function (value) {
      const trimmedValue = value.trim()
      this.encodedSearchText = trimmedValue.replace(/ /g, '+').replace(/%20/g, '+').replace(/%2B/g, '+')
      return trimmedValue.replace(/\+/g, ' ').replace(/%20/g, ' ').replace(/%2B/g, ' ')
    },
    actualSuggestionPosition: function (value) {
      if (value === -1) {
        document.querySelectorAll('.search-text')[0].focus()
      } else {
        document.querySelectorAll('.suggestion')[value].focus()
      }
    }
  }
}
</script>
