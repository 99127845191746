<template>
  <div class="content">
    <h3 class="title">{{ $t("contact.title") }}</h3>
    <form :data-token="getFormToken" action="" v-if="!successMessage && !globalError">
      <div class="form-input name" :class="errorClass(form.nameError)">
        <input type="text" name="name" :placeholder="this.$t('contact.name.placeholder')" v-model="form.name">
        <div class="error-message" v-if="form.nameError" v-html="form.nameError"></div>
      </div>
      <div class="form-input mail" :class="errorClass(form.mailError)">
        <input type="text" name="mail" :placeholder="this.$t('contact.mail.placeholder')" v-model="form.mail">
        <div class="error-message" v-if="form.mailError" v-html="form.mailError"></div>
      </div>
      <div class="form-input message" :class="errorClass(form.messageError)">
        <autosizing-textarea type="text" name="message" :placeholder="this.$t('contact.message.placeholder')" v-model="form.message"></autosizing-textarea>
        <div class="error-message" v-if="form.messageError" v-html="form.messageError"></div>
      </div>
      <div class="form-type-checkbox">
        <div class="wrapper" :aria-label="$t('contact.agree.longText')" v-tippy="$t('contact.agree.longText')">
          <div class="error-message" v-if="agreementError"><span>{{ $t('contact.agree.error') }}</span></div>
          <input id="agree" @change="agreementCheckboxHandler" name="agree[agree]" value="agree" class="form-checkbox contact-agree" type="checkbox">
          <label for="agree" class="option contact-agree-label">{{ $t('contact.agree.shortText') }}</label>
        </div>
      </div>
      <div class="form-input attachments">
          <div class="label">{{ $t('contact.attachments.label') }}</div>
          <div class="files-list" v-bind:class="filesListClass">
            <template :key="index" v-for="(file, index) in filesList">
              <div class="file"><span class="remove" @click="removeElement(index)"></span> <span class="file-name">{{ file.file_name }}</span> <span class="error-message" v-if="form.attachementsError && form.attachementsError[index]" v-html="form.attachementsError[index]"></span></div>

            </template>
          </div>
          <div class="upload-area">
            <p>{{ $t('contact.attachments.text') }}</p>
          </div>
          <div class="description">{{ $t('contact.attachments.description') }}</div>
          <div v-if="recaptchaIsActive" class="google-privacy" v-html="$t('contact.recaptcha.policy')"></div>
      </div>
      <div class="form-input submit" :class="waitingClass">
        <input type="submit" name="submit" :value="this.$t('contact.submit')" @click.prevent="onSubmit">
      </div>
    </form>
    <div class="success" v-if="successMessage && !globalError" v-html="successMessage"></div>
    <div class="success" v-if="!successMessage && globalError" v-html="globalError"></div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import niceScroll from 'jquery.nicescroll'
/* eslint-enable no-unused-vars */
import { logger } from '@/helpers/logger.js'
import AutosizingTextarea from '@/components/forms/AutosizingTextarea.vue'
import { dev } from '@/components/mixins/devMixin.js'
import { recaptcha } from '@/components/mixins/recaptchaMixin.js'

export default {
  mixins: [dev, recaptcha],
  props: {
    done: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      endpointUrl: '/api/form/contact',
      formToken: '',
      form: {
        name: '',
        nameError: '',
        mail: '',
        mailError: '',
        message: '',
        messageError: '',
        attachments: [],
        attachmentsError: []
      },
      globalError: '',
      successMessage: '',
      waitingForResponse: false,
      filesListClass: '',
      agreementError: false
    }
  },
  methods: {
    getFiles (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const reader = new FileReader()

        reader.readAsDataURL(file)
        reader.onloadend = () => {
          const fileInfo = {
            file_name: file.name,
            type: file.type,
            size: Math.round(file.size / 1000),
            file: reader.result.split(',')[1]
          }

          this.form.attachments.push(fileInfo)
          if (this.form.attachments.length === files.length) {
            if (this.multiple) this.done(this.form.attachments)
            else this.done(this.form.attachments)
          }
        }
      }
    },
    makeDroppable (element, callback) {
      const input = document.createElement('input')

      input.setAttribute('type', 'file')
      input.setAttribute('multiple', true)
      input.style.display = 'none'

      input.addEventListener('change', attachmentsChange)

      element.appendChild(input)

      element.addEventListener('dragover', function (e) {
        e.preventDefault()
        e.stopPropagation()
        element.classList.add('dragover')
      })

      element.addEventListener('dragleave', function (e) {
        e.preventDefault()
        e.stopPropagation()
        element.classList.remove('dragover')
      })

      element.addEventListener('drop', function (e) {
        e.preventDefault()
        e.stopPropagation()
        element.classList.remove('dragover')
        attachmentsChange(e)
      })

      element.addEventListener('click', function () {
        input.value = null
        input.click()
      })

      function attachmentsChange (e) {
        let files
        if (e.dataTransfer) {
          files = e.dataTransfer.files
        } else if (e.target) {
          files = e.target.files
        }
        /* eslint-disable no-useless-call */
        callback.call(null, files)
      }
    },
    errorClass (error) {
      if (error) {
        return 'error'
      } else {
        return ''
      }
    },
    resetValidation () {
      this.form.nameError = ''
      this.form.mailError = ''
      this.form.messageError = ''
      this.form.attachementsError = []
    },
    recaptchaIsActive () {
      return process.env.VUE_APP_CHINA_VERSION !== '' && process.env.VUE_APP_RECAPTCHA_SITE_KEY && process.env.VUE_APP_RECAPTCHA_SITE_KEY !== ''
    },
    onSubmit (e) {
      const vm = this
      const form = e.target.parentNode.parentNode
      const agreement = form.querySelectorAll('.contact-agree')[0]
      const agreementLabel = form.querySelectorAll('.contact-agree-label')[0]

      if (agreement.checked === false) {
        agreementLabel.classList.add('error')
        vm.agreementError = true
      } else {
        if (vm.recaptchaIsActive) {
          /* eslint-disable no-undef */
          grecaptcha.ready(function () {
            grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'contact_form_send' }).then(function (recaptchaToken) {
              vm.submitHandler(recaptchaToken)
            })
          })
          /* eslint-enable no-undef */
        } else {
          vm.submitHandler(process.env.VUE_APP_RECAPTCHA_DISABLED_KEY)
        }
      }
    },
    submitHandler (recaptchaToken) {
      if (!this.waitingForResponse) {
        const files = []

        this.waitingForResponse = true
        this.resetValidation()
        for (let i = 0; i < this.form.attachments.length; i++) {
          files[i] = {
            file_name: this.form.attachments[i].file_name,
            file: this.form.attachments[i].file
          }
        }
        const data = {
          data: {
            name: this.form.name,
            mail: this.form.mail,
            message: this.form.message,
            agreement: this.$t('contact.agree.shortText'),
            field_contact_attachment: files,
            recaptcha_token: recaptchaToken
          }
        }
        const language = this.$i18n.locale()
        const url = process.env.VUE_APP_ENDPOINT_URL + language + this.endpointUrl
        let options = {}
        if (process.env.VUE_APP_ENDPOINT_AUTHORIZATION_HEADER !== '') {
          const authHeader = 'Basic ' + btoa(process.env.VUE_APP_ENDPOINT_AUTHORIZATION_HEADER)
          options = {
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          }
        } else {
          options = {
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          }
        }
        logger('Contact form endpoint:', options)
        const localValidation = this.localValidation(this.form.attachments)
        if (localValidation) {
          this.$axios.post(url, options).then(this.successCallBack, this.errorCallBack)
          this.waitingForResponse = false
        } else {
          logger('Local validation errors')
          this.waitingForResponse = false
        }
        this.formToken = Date.now()
      }
    },
    successCallBack: function (response) {
      this.waitingForResponse = false
      switch (response.data.status) {
        case 'error':
          logger('Contact form response error:', response.data.data)
          this.formToken = Date.now()
          break
        case 'validation_error':
          logger('Contact form validation errors', response.data.data)
          if (response.data.data.name) {
            this.form.nameError = response.data.data.name
          }
          if (response.data.data.mail) {
            this.form.mailError = response.data.data.mail
          }
          if (response.data.data.message && response.data.data.message[0]) {
            this.form.messageError = response.data.data.message[0]
          }
          if (response.data.data.field_contact_attachment) {
            this.form.attachementsError = response.data.data.field_contact_attachment
          }
          if (response.data.data.general) {
            this.globalError = response.data.data.general
          }
          this.formToken = Date.now()
          break
        case 'ok':
          logger('Contact form response ok:', response)
          if (response.data.data) {
            this.successMessage = response.data.data
          }
          this.formToken = Date.now()
      }
    },
    errorCallBack: function (response) {
      logger('Contact form ERROR', response)
    },
    removeElement: function (index) {
      this.form.attachments.splice(index, 1)
      this.form.attachementsError = []
    },
    localValidation: function (files) {
      let errors = 0
      const maxFileSize = 2000
      const allowedExtensions = ['text/plain', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/gif', 'image/jpeg', 'image/png']
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > maxFileSize) {
          errors++
          this.form.attachementsError[i] = this.$t('contact.error.filesize')
        }
        if (!allowedExtensions.includes(files[i].type)) {
          errors++
          if (this.form.attachementsError[i]) {
            this.form.attachementsError[i] += this.$t('contact.error.filetype')
          }
          this.form.attachementsError[i] = this.$t('contact.error.filetype')
        }
      }
      return !errors
    },
    scroll: function () {
      $('.contact-form .attachments .files-list').niceScroll({
        cursorcolor: '#ffffff',
        autohidemode: false,
        cursorborderradius: 0,
        cursorwidth: 10,
        cursorborder: 'none',
        background: '#ffffff',
        grabcursorenabled: true
      })
    },
    agreementCheckboxHandler (e) {
      const agreementLabel = e.target.parentNode.querySelectorAll('.contact-agree-label')[0]
      agreementLabel.classList.remove('error')
      this.agreementError = false
    }
  },
  computed: {
    filesList: {
      get: function () {
        if (this.form.attachments.length > 0) {
          this.filesListClass = 'scroll' // eslint-disable-line vue/no-side-effects-in-computed-properties
        } else {
          this.filesListClass = '' // eslint-disable-line vue/no-side-effects-in-computed-properties
        }
        return this.form.attachments
      }
    },
    getFormToken: {
      get: function () {
        return this.formToken
      }
    },
    waitingClass: {
      get: function () {
        if (this.waitingForResponse) {
          return 'waiting'
        } else {
          return ''
        }
      }
    }
  },
  mounted () {
    this.loadRecaptcha()

    this.$nextTick(function () {
      const element = document.querySelector('.upload-area')
      this.makeDroppable(element, this.getFiles)
      this.scroll()
    })
  },
  beforeUnmount () {
    $('.contact-form .attachments .files-list').getNiceScroll().remove()
  },
  components: {
    autosizingTextarea: AutosizingTextarea
  }
}
</script>
