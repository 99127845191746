<template>
  <footer v-if="!maintenance">
    <div class="container">
      <hr />
      <div class="footer-logos">
        <div class="footer-logos-flex">
          <div class="footer-logo footer-logo--mkidn">
            <template v-if="currentLanguage === 'pl'">
              <a href="https://www.gov.pl/web/kultura" target="_blank" rel="nofollow noopener noreferrer">
                <img v-if="!highContrast"
                     v-lazy="'/static/img/mkidn-logo.png'"
                     :alt="$t('footer.mkdnis-logo.alt')"
                     width="211"
                     height="52"/>
                <img v-else
                     v-lazy="'/static/img/mkidn-logo-hc.png'"
                     :alt="$t('footer.mkdnis-logo.alt')"
                     width="211"
                     height="52"/>
                <span class="visually-hidden">{{ $t('global.new-tab-info') }}</span>
              </a>
            </template>
            <template v-else>
              <a href="https://www.gov.pl/web/kultura" target="_blank" rel="nofollow noopener noreferrer">
                <img v-if="!highContrast"
                     v-lazy="'/static/img/mkidn-logo-en.png'"
                     :alt="$t('footer.mkdnis-logo.alt')"
                     width="189"
                     height="52"/>
                <img v-else
                     v-lazy="'/static/img/mkidn-logo-en-hc.png'"
                     :alt="$t('footer.mkdnis-logo.alt')"
                     width="189"
                     height="52"/>
                <span class="visually-hidden">{{ $t('global.new-tab-info') }}</span>
              </a>
            </template>
          </div>
          <div class="footer-logo footer-logo--culture">
            <template v-if="currentLanguage === 'pl'">
              <a :href="$t('footer.iam.url')" target="_blank" rel="noopener">
                <img v-if="!highContrast"
                     v-lazy="'/static/img/logo-culture-iam.svg'"
                     alt="Culture.pl logo"
                     width="157"
                     height="52"/>
                <img v-else
                     v-lazy="'/static/img/logo-culture-iam-hc.svg'"
                     alt="Culture.pl logo"
                     width="157"
                     height="52"/>
                <span class="visually-hidden">{{ $t('global.new-tab-info') }}</span>
              </a>
            </template>
            <template v-else>
              <a :href="$t('footer.iam.url')" target="_blank" rel="noopener">
                <img v-if="!highContrast"
                     v-lazy="'/static/img/logo-culture-iam-en.svg'"
                     alt="Culture.pl logo"
                     width="157"
                     height="52"/>
                <img v-else
                     v-lazy="'/static/img/logo-culture-iam-en-hc.svg'"
                     alt="Culture.pl logo"
                     width="157"
                     height="52"/>
                <span class="visually-hidden">{{ $t('global.new-tab-info') }}</span>
              </a>
            </template>
          </div>
        </div>
        <div class="footer-logos-info">
          <p v-html="$t('footer.logos.info')"></p>
        </div>
      </div>
      <div class="footer-info">
        <p v-html="$t('footer.info')"></p>
      </div>
      <app-footer-menu></app-footer-menu>
      <app-newsletter-footer></app-newsletter-footer>
      <app-contact-popup></app-contact-popup>
    </div>
  </footer>
</template>

<script>
import NewsletterFooter from '@/components/common/NewsletterFooter.vue'
import ContactPopup from '@/components/popups/ContactPopup.vue'
import { highContrast } from '@/components/mixins/highContrastMixin.js'
import { dev } from '@/components/mixins/devMixin.js'
import { lazyLoader } from '@/components/mixins/lazyLoaderMixin.js'
import FooterMenu from '@/components/menus/FooterMenu.vue'

export default {
  mixins: [dev, highContrast, lazyLoader],
  computed: {
    currentLanguage: function () {
      return this.$i18n.locale()
    },
    maintenance: function () {
      return this.$store.getters.getMaintenanceState
    }
  },
  components: {
    appFooterMenu: FooterMenu,
    appNewsletterFooter: NewsletterFooter,
    appContactPopup: ContactPopup
  }
}
</script>
