<template>
  <div class="newsletter newsletter-footer">
    <component :is="'appNewsletterForm'"
               :addSuffix="'footer'"></component>
  </div>
</template>

<script>
import NewsletterForm from '@/components/forms/Newsletter.vue'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev],
  components: {
    appNewsletterForm: NewsletterForm
  }
}
</script>
