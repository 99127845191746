<template>
  <Teleport to="body" v-if="visible">
    <div id="contact-popup" class="popup" aria-live="assertive" role="alert">
      <div class="popup-wrapper">
        <div class="popup-wrapper-inner">
          <div class="contact-form">
            <div @click="toggleContactPopup" class="close"></div>
            <component :is="'ContactForm'"></component>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { mapGetters } from 'vuex'
import ContactForm from '@/components/forms/Contact.vue'
import { popup } from '@/components/mixins/popupMixin.js'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev, popup],
  computed: {
    ...mapGetters({
      updateTime: 'getUpdateTime'
    })
  },
  methods: {
    contactESCPressed () {
      if (this.visible) {
        this.closeContactPopup()
      }
    },
    toggleContactPopup (event) {
      const currentWidth = this.$store.getters.getWindowWidth
      const show = !this.visible

      if (show) {
        this.setScrollPosition()
      }
      if (currentWidth >= 768) {
        event.preventDefault()
        this.visible = !this.visible
        document.body.classList.toggle('noscroll')

        if (show) {
          window.history.pushState('forward', 'Popup', window.location.href + '#popup')
        } else {
          window.history.pushState('', document.title, window.location.pathname + window.location.search)
        }
      } else {
        const url = new URL(event.target.href)
        this.$router.push(url.pathname)
      }
    },
    closeContactPopup () {
      this.visible = false
      document.body.classList.toggle('noscroll')
    }
  },
  mounted () {
    this.emitter.on('ESCPressed', this.contactESCPressed)
    $(window).on('popstate', function () {
      $('.contact-form .close').click()
    })
  },
  beforeUnmount () {
    this.emitter.off('ESCPressed', this.contactESCPressed)
    const popup = document.getElementsByClassName('contact-popup')
    for (let i = 0; i < popup.length; i++) {
      // Remove all event listeners
      popup[i].outerHTML = popup[i].outerHTML // eslint-disable-line no-self-assign
    }
  },
  watch: {
    'updateTime' () {
      const popup = document.getElementsByClassName('contact-popup')
      for (let i = 0; i < popup.length; i++) {
        // Remove all event listeners
        popup[i].outerHTML = popup[i].outerHTML // eslint-disable-line no-self-assign
        // Add new listener
        popup[i].addEventListener('click', event => this.toggleContactPopup(event))
      }
    },
    visible (val) {
      if (val === true) {
        this.$store.dispatch('GTMHelperPushGAEvent', {
          category: 'popupOpen',
          action: 'open',
          label: 'Contact popup'
        })
      }
    }
  },
  components: {
    ContactForm
  }
}
</script>
