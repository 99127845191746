import { localeMapperMoment } from '@/helpers/localeMapperMoment.js'
import { useI18nPlugin } from '@unify/vuex-i18n'

const yearDate = function (date) {
  const i18n = useI18nPlugin()
  const locale = i18n.locale()
  const localeMapped = localeMapperMoment(locale)
  moment.locale(localeMapped)
  const yearDate = moment(date)
  return yearDate.format('YYYY')
}

export {
  yearDate
}
