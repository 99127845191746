import { logger } from '@/helpers/logger.js'

export const recaptcha = {
  methods: {
    loadRecaptcha () {
      // Google Recaptcha
      if (process.env.VUE_APP_CHINA_VERSION !== '' && process.env.VUE_APP_RECAPTCHA_SITE_KEY && process.env.VUE_APP_RECAPTCHA_SITE_KEY !== '') {
        const siteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY
        const recaptchaSrc = 'https://www.google.com/recaptcha/api.js?render=' + siteKey
        const recaptchaLength = document.querySelectorAll('script[src="' + recaptchaSrc + '"]').length

        if (recaptchaLength) {
          logger('Recaptcha loaded already: ' + process.env.VUE_APP_RECAPTCHA_SITE_KEY)
        } else {
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.src = recaptchaSrc
          document.head.appendChild(script)
          logger('Recaptcha loaded: ' + process.env.VUE_APP_RECAPTCHA_SITE_KEY)
        }
      }
    }
  }
}
