export const popup = {
  props: {
    fullPath: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      windowScrollTop: 100,
      visible: false
    }
  },
  methods: {
    setScrollPosition () {
      this.windowScrollTop = window.pageYOffset || document.documentElement.scrollTop
    },
    getPath () {
      if (this.$props.fullPath) {
        if (this.node.path_alias) {
          return this.node.path_alias
        } else {
          return '/' + this.node.langcode + this.node.path.alias
        }
      } else {
        return '/' + this.node.langcode + this.node.path.alias
      }
    }
  },
  watch: {
    visible (state) {
      if (state === false) {
        document.documentElement.scrollTop = this.windowScrollTop
      }
    }
  }
}
