<template>
  <textarea rows="1" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"></textarea>
</template>

<script>
import autosize from 'autosize'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev],
  props: ['modelValue'],
  emits: ['update:modelValue'],
  mounted: function () {
    autosize(this.$el)
  }
}
</script>
