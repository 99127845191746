'use strict'

import Cookies from 'js-cookie'
import { logger } from '@/helpers/logger.js'
/* eslint-disable no-unused-vars */
import scrollDepth from 'scroll-depth'
/* eslint-enable no-unused-vars */

export class GTMHelper {
  emptyValue = null
  scrollDepth = null
  enableConditions = process.env.VUE_APP_CHINA_VERSION !== '' &&
    process.env.VUE_APP_USE_OLD_TAG_MANAGER_IMPLEMENTATION !== false &&
    process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID &&
    process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID !== ''

  constructor () {
    this.nullDataLayer()
    this.windowDataLayer = window.dataLayer || []
    if (this.enableConditions) {
      this.scrollDepth = $.scrollDepth({
        elements: ['#mct', '#mctp', '#mcte']
      })
    }
  }

  nullDataLayer () {
    this.dataLayer = {
      cultureContentType: this.emptyValue,
      cultureContentLanguage: this.emptyValue,
      cultureTitle: this.emptyValue,
      cultureLoggedIn: this.emptyValue,
      cultureCategories: {
        main: this.emptyValue,
        topics: this.emptyValue
      },
      cultureTags: this.emptyValue,
      cultureFirstLetter: this.emptyValue,
      cultureContentSubtypes: this.emptyValue,
      cultureCity: this.emptyValue,
      cultureAuthor: this.emptyValue,
      cultureKnowledgeLevel: this.emptyValue,
      cultureSeriesName: this.emptyValue,
      cultureRealPagePath: this.emptyValue,
      cultureCurrentPage: this.emptyValue
    }
  }

  resetScrollDepth () {
    scrollDepth.reset()
  }

  pushPageLoadEvent (url) {
    if (this.enableConditions) {
      this.nullDataLayer()
      this.windowDataLayer.push({
        event: 'PageLoad',
        url
      })
    }
  }

  pushPageLoadedEvent (entity) {
    if (this.enableConditions) {
      // null all attributes before set new values
      this.nullDataLayer()

      // set dataLayer attributes based on entity data
      this.processCommon()

      if (entity.generalType) {
        switch (entity.generalType) {
          case 'node':
            this.processNode(entity)
            break
          case 'term':
            this.processTerm(entity)
            break
          case 'view':
            this.processView(entity)
            break
          case 'staticPage':
            this.processStaticPage(entity)
            break
          case 'user':
            this.processUser(entity)
            break
        }
      }

      logger('dataLayer', this.dataLayer)
      this.windowDataLayer.push(this.dataLayer)
      this.windowDataLayer.push({
        event: 'PageLoaded',
        url: document.location.pathname
      })
      this.resetScrollDepth()
    }
  }

  processCommon () {
    // set default for culturePageType
    this.dataLayer.culturePageType = 'standard'
    // check SESS_uat cookie presence to determinate logged user
    this.dataLayer.cultureLoggedIn = Cookies.get('SESS_uat') !== undefined
  }

  processNode (entity) {
    if (entity.type_org) {
      this.dataLayer.cultureContentType = entity.type_org
    }

    if (entity.langcode) {
      this.dataLayer.cultureContentLanguage = entity.langcode
    }

    if (entity.title) {
      this.dataLayer.cultureTitle = entity.title
    }

    if (entity.field_topic && entity.field_topic[0]) {
      this.dataLayer.cultureCategories.main = entity.field_topic[0].name
    }

    if (entity.field_topic && entity.field_topic[1]) {
      this.dataLayer.cultureCategories.topics = this.generateStringFromMultivaluedTerm(entity.field_topic, '/', 1)
    }

    if (entity.field_tags && entity.field_tags[0]) {
      this.dataLayer.cultureTags = this.generateStringFromMultivaluedTerm(entity.field_tags, ';', 0, 150)
    }

    if (entity.field_alfabet && entity.field_alfabet.name) {
      this.dataLayer.cultureFirstLetter = entity.field_alfabet.name
    }

    if (entity.field_type_of_artist) {
      this.dataLayer.cultureContentSubtypes = this.generateStringFromMultivaluedTerm(entity.field_type_of_artist)
    }

    if (entity.field_type_of_place) {
      this.dataLayer.cultureContentSubtypes = this.generateStringFromMultivaluedTerm(entity.field_type_of_place)
    }

    if (entity.field_type_of_work) {
      this.dataLayer.cultureContentSubtypes = entity.field_type_of_work.name
    }

    if (entity.field_event_cities) {
      this.dataLayer.cultureCity = this.generateStringFromMultivaluedTerm(entity.field_event_cities)
    }

    if (entity.uid && entity.uid.field_user_full_name) {
      this.dataLayer.cultureAuthor = entity.uid.field_user_full_name
    }

    if (entity.field_knowledge_level && entity.field_knowledge_level.name) {
      this.dataLayer.cultureKnowledgeLevel = entity.field_knowledge_level.name
    }

    // gallery and video in popup mode
    if (entity.popupMode) {
      this.dataLayer.culturePageType = 'popup'
    }

    if (entity.field_series) {
      this.dataLayer.cultureSeriesName = this.generateStringFromMultivaluedTerm(entity.field_series)
    }

    if (entity.langcode && entity.path_alias) {
      this.dataLayer.cultureRealPagePath = '/' + entity.langcode + entity.path_alias
    }
  }

  processTerm (entity) {
    if (entity.type_org) {
      this.dataLayer.cultureContentType = entity.type_org
    }
    if (entity.name) {
      this.dataLayer.cultureTitle = entity.name
    }
    if (entity.langcode && entity.langcode !== 'und') {
      this.dataLayer.cultureContentLanguage = entity.langcode
    } else if (entity.appLangcode) {
      this.dataLayer.cultureContentLanguage = entity.appLangcode
    }

    if (entity.path && entity.path.langcode && entity.path.alias && entity.path.langcode !== 'und') {
      this.dataLayer.cultureRealPagePath = '/' + entity.path.langcode + entity.path.alias
    } else if (entity.path && entity.path.alias && entity.appLangcode) {
      this.dataLayer.cultureRealPagePath = '/' + entity.appLangcode + entity.path.alias
    }
  }

  processView (data) {
    this.dataLayer.cultureContentLanguage = data.contentLanguage
    this.dataLayer.cultureContentType = data.contentType
    this.dataLayer.cultureRealPagePath = data.realPagePath
    this.dataLayer.cultureTitle = data.title
    this.dataLayer.cultureCurrentPage = data.currentPage
  }

  processStaticPage (data) {
    this.dataLayer.cultureContentLanguage = data.contentLanguage
    this.dataLayer.cultureContentType = data.contentType
    this.dataLayer.cultureRealPagePath = data.realPagePath
    this.dataLayer.cultureTitle = data.title
  }

  processUser (entity) {
    if (entity.type_org) {
      this.dataLayer.cultureContentType = entity.type_org
    }
    if (entity.name) {
      this.dataLayer.cultureTitle = entity.name
      this.dataLayer.cultureAuthor = entity.name
    }
    if (entity.langcode && entity.langcode !== 'und') {
      this.dataLayer.cultureContentLanguage = entity.langcode
    } else if (entity.appLangcode) {
      this.dataLayer.cultureContentLanguage = entity.appLangcode
    }

    if (entity.path && entity.path.langcode && entity.path.alias && entity.path.langcode !== 'und') {
      this.dataLayer.cultureRealPagePath = '/' + entity.path.langcode + entity.path.alias
    } else if (entity.path && entity.path.alias && entity.appLangcode) {
      this.dataLayer.cultureRealPagePath = '/' + entity.appLangcode + entity.path.alias
    }
  }

  pushGAEvent (eventData) {
    if (this.enableConditions) {
      const event = {}

      // text
      // Constant value
      // 'GAEvent'
      event.event = 'GAEvent'

      // text
      // required: yes
      // Typically the object that was interacted with (e.g. 'Video')
      event.eventCategory = eventData.category

      // text
      // required: yes
      // The type of interaction (e.g. 'play')
      event.eventAction = eventData.action

      if (eventData.label && eventData.label !== '') {
        // text
        // required: no
        // Useful for categorizing events (e.g. 'Fall Campaign')
        event.eventLabel = eventData.label
      }

      if (eventData.value && eventData.value !== '') {
        // integer
        // required: no
        // A numeric value associated with the event (e.g. 42)
        event.eventValue = eventData.value
      }
      logger('GAEvent', event)
      this.windowDataLayer.push(event)
    }
  }

  generateStringFromMultivaluedTerm (field, separator = ';', skip = 0, trim = null) {
    let text = ''
    field.forEach(function (val, index) {
      if (index >= skip) {
        if (!trim || (trim && ((text + val.name).length <= trim))) {
          text += val.name + separator
        }
      }
    })
    if (text !== '') {
      text = text.substring(0, text.length - 1)
      return text
    } else {
      return this.emptyValue
    }
  }
}
