<template>
    <div class="sidebar sidebar-right" :key="currentLanguage" v-if="!maintenance">
      <div class="sidebar-inner">
        <ul>
          <li v-if="!isChinaVersion && currentLanguage === 'pl'"><a href="https://www.facebook.com/culturepl/" target="_blank" rel="noopener" @click="socialMediaLinkClick">Facebook </a></li>
          <li v-if="!isChinaVersion && currentLanguage === 'jp'"><a href="https://www.facebook.com/culturepl.jp" target="_blank" rel="noopener" @click="socialMediaLinkClick">Facebook </a></li>
          <li v-if="!isChinaVersion && currentLanguage === 'kr'"><a href="https://www.facebook.com/culturepl.kr" target="_blank" rel="noopener" @click="socialMediaLinkClick">Facebook </a><a href="https://www.instagram.com/culture_pl_kr/" target="_blank" rel="noopener" @click="socialMediaLinkClick"> Instagram</a> </li>
          <li v-if="!isChinaVersion && currentLanguage === 'zht'"><a href="https://weibo.com/u/6170455963?fbclid=IwAR1QDEk2tJ4Bcr7tg8VzNS7dWV4E0eVi00Lw9da5sHlz2XXzsX3Uqh9bw8A&is_all=1" target="_blank" rel="noopener" @click="socialMediaLinkClick">WEIBO</a> </li>
          <li v-if="!isChinaVersion && currentLanguage === 'zhs'"><a href="https://weibo.com/u/6170455963?fbclid=IwAR1QDEk2tJ4Bcr7tg8VzNS7dWV4E0eVi00Lw9da5sHlz2XXzsX3Uqh9bw8A&is_all=1" target="_blank" rel="noopener" @click="socialMediaLinkClick">WEIBO</a> </li>
          <li v-if="!isChinaVersion && currentLanguage === 'ru'"><a href="https://www.facebook.com/culturepl.ru/" target="_blank" rel="noopener" @click="socialMediaLinkClick">Facebook</a></li>
          <li v-if="!isChinaVersion && currentLanguage === 'ua'"><a href="https://www.facebook.com/culturepl.ua" target="_blank" rel="noopener" @click="socialMediaLinkClick">Facebook</a></li>
          <li v-if="!isChinaVersion && currentLanguage === 'pl'"><a href="https://twitter.com/culture_pl" target="_blank" rel="noopener" @click="socialMediaLinkClick">Twitter</a></li>
          <li v-if="!isChinaVersion && currentLanguage === 'ru'"><a href="https://vk.com/culture_pl" target="_blank" rel="noopener" @click="socialMediaLinkClick">VK</a></li>
          <li><a :href="$t('newsletter.url')" class="newsletter-popup"><span class="mobile-only">{{ $t("sidebar.right.newsletter-sign-up.label.short") }}</span><span class="desktop-only">{{ $t("sidebar.right.newsletter-sign-up.label") }}</span></a></li>
        </ul>
      </div>
    </div>
</template>

<script>
import { chinaVersion } from '@/components/mixins/chinaVersionMixin.js'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev, chinaVersion],
  methods: {
    socialMediaLinkClick (event) {
      // push GAEvent
      this.$store.dispatch('GTMHelperPushGAEvent', {
        category: 'socialMedia',
        action: 'link',
        label: event.currentTarget.getAttribute('href')
      })
    }
  },
  computed: {
    currentLanguage: function () {
      return this.$i18n.locale()
    },
    maintenance: function () {
      return this.$store.getters.getMaintenanceState
    }
  }
}
</script>
