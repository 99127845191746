/**
 * Maps language prefix to langcode.
 *
 * @param string language
 * @returns string
 */
function localeMapperMoment (language) {
  switch (language) {
    case 'kr':
      return 'ko'
    case 'jp':
      return 'ja'
    case 'zht':
      return 'zh-hant'
    case 'zhs':
      return 'zh-hans'
    case 'ua':
      return 'uk'
    default:
      return language
  }
}

export { localeMapperMoment }
