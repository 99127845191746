<template>
  <transition :enter-active-class="'showing'" :leave-active-class="'hiding'" mode="out-in">
    <div id="preloader" :class="classes()">
    </div>
  </transition>
</template>

<script>
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev],
  methods: {
    classes () {
      const targetComponent = this.$store.getters.getTargetRouteName

      if (targetComponent) {
        return 'route-to-' + targetComponent
      } else {
        return ''
      }
    }
  }
}
</script>
