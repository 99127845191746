const stickInParent = {
  created: function () {
    require('@node_modules/sticky-kit/dist/sticky-kit.min')
  },
  bind: function (el, binding) {
    const jQuery = $

    jQuery(document).ready(function () {
      const sticky = binding.value || {}
      const options = sticky.options || {}
      const events = sticky.on || {}

      jQuery(el).stick_in_parent(options)

      for (const i in events) {
        jQuery(el).on(i, events[i])
      }
    })
  }
}

export {
  stickInParent
}
