function waitFor (variable, callback) {
  const interval = setInterval(function () {
    if (window[variable]) {
      clearInterval(interval)
      callback()
    }
  }, 200)
}

export { waitFor }
