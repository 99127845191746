<template>
  <div :class="mode !== 'footer' ? 'newsletter-content' : ''">
    <div class="content">
      <template v-if="mode === 'footer'">
        <h3 class="title">
          {{ $t("newsletter.title") }}
        </h3>
        <div class="description">
          <p>{{ $t("newsletter.description") }}</p>
        </div>
      </template>
      <template  v-if="mode === 'popup' || mode === 'page'">
        <div class="logo">
          <img v-if="!highContrast" alt="Culture.pl" src="/static/img/logo.svg"/>
          <img v-else alt="Culture.pl" src="/static/img/logo-hc.svg"/>
        </div>
        <h3 class="title">{{ $t("newsletter.popup.title") }}</h3>
        <div class="text"><p v-html="$t('newsletter.popup.text')"></p></div>
        <div class="description"><p v-html="$t('newsletter.popup.description')"></p></div>
      </template>
      <form action="" v-if="!successMessage && !globalError">
        <div class="form-input mail" :class="errorClass(form.mailError)">
          <input type="text" name="mail" aria-label="email" :placeholder="this.$t('newsletter.placeholder')"
                 v-model="form.mail" @focus="emailInputFocus">
          <div class="error-message" v-if="form.mailError" v-html="form.mailError"></div>
        </div>
        <div class="form-input submit" :class="waitingClass">
          <input type="submit" name="submit" :value="this.$t('newsletter.submit')"
                 :aria-label="this.$t('newsletter.submit')" @click.prevent="onSubmit">
        </div>
        <div class="form-type-checkbox">
          <div class="wrapper" :aria-label="$t('newsletter.checkbox.longText')"
               v-tippy="$t('newsletter.checkbox.longText')">
            <div class="error-message" v-if="agreementError"><span>{{ $t('newsletter.checkbox.error') }}</span></div>
            <input :id="'agree' + newsletterID" @change="agreementCheckboxHandler"
                   name="agree"
                   value="agree"
                   class="form-checkbox newsletter-agree"
                   type="checkbox">
            <label :for="'agree' + newsletterID"
                   class="option newsletter-agree-label">{{ $t('newsletter.checkbox.shortText') }}</label>
          </div>
        </div>
        <div v-if="recaptchaIsActive" class="google-privacy" v-html="$t('newsletter.recaptcha.policy')"></div>
      </form>
      <div class="success" v-if="successMessage && !globalError" v-html="successMessage"></div>
      <div class="success" v-if="!successMessage && globalError" v-html="globalError"></div>
    </div>
  </div>
</template>

<script>
import { logger } from '@/helpers/logger.js'
import { waitFor } from '@/helpers/waitFor.js'
import { dev } from '@/components/mixins/devMixin.js'
import { recaptcha } from '@/components/mixins/recaptchaMixin.js'
import { highContrast } from '@/components/mixins/highContrastMixin.js'

export default {
  mixins: [dev, highContrast, recaptcha],
  props: {
    done: {
      type: Function,
      default: () => {
      }
    },
    mode: {
      type: String,
      default: 'footer' // footer - footer (inline) form, popup - popup version, page - separate routing version
    }
  },
  data () {
    return {
      endpointUrl: '/api/newsletter/signup',
      form: {
        mail: '',
        mailError: ''
      },
      globalError: '',
      successMessage: '',
      waitingForResponse: false,
      agreementError: false,
      newsletterID: Date.now()
    }
  },
  methods: {
    errorClass (error) {
      if (error) {
        return 'error'
      } else {
        return ''
      }
    },
    resetValidation () {
      this.form.mailError = ''
    },
    recaptchaIsActive () {
      return process.env.VUE_APP_CHINA_VERSION !== '' && process.env.VUE_APP_RECAPTCHA_SITE_KEY && process.env.VUE_APP_RECAPTCHA_SITE_KEY !== ''
    },
    onSubmit (e) {
      const vm = this
      const form = e.target.parentNode.parentNode
      const agreement = form.querySelectorAll('.newsletter-agree')[0]
      const agreementLabel = form.querySelectorAll('.newsletter-agree-label')[0]

      if (agreement.checked === false) {
        agreementLabel.classList.add('error')
        vm.agreementError = true
      } else {
        if (vm.recaptchaIsActive) {
          if (this.mode === 'footer') {
            // It's footer mode, so recaptcha wasn't loaded on mounted
            this.loadRecaptcha()
          }
          /* eslint-disable no-undef */
          waitFor('grecaptcha', () => {
            grecaptcha.ready(function () {
              grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'newsletter_form_send' }).then(function (recaptchaToken) {
                vm.submitHandler(recaptchaToken)
              })
            })
          })
          /* eslint-enable no-undef */
        } else {
          vm.submitHandler(process.env.VUE_APP_RECAPTCHA_DISABLED_KEY)
        }
      }
    },
    submitHandler (recaptchaToken) {
      if (!this.waitingForResponse) {
        this.waitingForResponse = true
        this.resetValidation()
        const data = {
          data: {
            mail: this.form.mail,
            agreement: this.$t('newsletter.checkbox.shortText'),
            recaptcha_token: recaptchaToken
          }
        }
        const language = this.$i18n.locale()
        const url = process.env.VUE_APP_ENDPOINT_URL + language + this.endpointUrl
        let options = {}
        if (process.env.VUE_APP_ENDPOINT_AUTHORIZATION_HEADER !== '') {
          const authHeader = 'Basic ' + btoa(process.env.VUE_APP_ENDPOINT_AUTHORIZATION_HEADER)
          options = {
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          }
        } else {
          options = {
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          }
        }
        logger('Newsletter signup form endpoint:', options)
        this.$axios.post(url, options).then(this.successCallBack, this.errorCallBack)
        this.waitingForResponse = false
        this.formToken = Date.now()
      }
    },
    successCallBack: function (response) {
      this.waitingForResponse = false
      switch (response.data.status) {
        case 'error':
          logger('Newsletter signup form response error:', response.data.data)
          this.formToken = Date.now()
          break
        case 'validation_error':
          logger('Newsletter signup form validation errors', response.data.data)
          if (response.data.data.mail) {
            this.form.mailError = response.data.data.mail
          }
          if (response.data.data.general) {
            this.globalError = response.data.data.general
          }
          this.formToken = Date.now()
          break
        case 'ok':
          logger('Newsletter signup form response ok:', response)
          if (response.data.data) {
            this.successMessage = response.data.data
          }
          this.$store.dispatch('GTMHelperPushGAEvent', {
            category: 'newsletterSubmit',
            action: 'click',
            label: this.mode
          })
          this.formToken = Date.now()
      }
    },
    errorCallBack: function (response) {
      logger('Newsletter signup form ERROR', response)
    },
    agreementCheckboxHandler (e) {
      const agreementLabel = e.target.parentNode.querySelectorAll('.newsletter-agree-label')[0]
      agreementLabel.classList.remove('error')
      this.agreementError = false
    },
    emailInputFocus () {
      this.$store.dispatch('GTMHelperPushGAEvent', {
        category: 'newsletterEmailInput',
        action: 'focus',
        label: this.mode
      })
    }
  },
  computed: {
    waitingClass: {
      get: function () {
        if (this.waitingForResponse) {
          return 'waiting'
        } else {
          return ''
        }
      }
    }
  },
  mounted () {
    if (this.mode === 'page' || this.mode === 'popup') {
      // Form is loaded in popup or page, so we can load recaptcha on mount
      this.loadRecaptcha()
    }
  }
}
</script>
