<script>
import { defineComponent } from 'vue'
import DOMPurify from 'dompurify'

const VHtml = defineComponent({
  name: 'VHtml',
  props: {
    html: {
      type: String,
      required: true
    }
  },
  directives: {
    swap: {
      mounted (el, binding) {
        // createContextualFragment allows script execution
        // so purify input first
        const safe = DOMPurify.sanitize(binding.value)
        const frag = document.createRange()
          .createContextualFragment(safe)
        el.replaceWith(frag)
      }
    }
  },
  // eslint-disable-next-line quotes
  template: `<div v-swap="html"></div>`
})

export default VHtml
</script>
