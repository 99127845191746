<template>
  <div class="lang-switch">
    <h3 class="label" v-if="translations">{{ $t('navigation.lang-switch.label') }}</h3>
    <ul class="links">
      <li v-for="(link, index) in translations" :key="index">
        <router-link @click="switchLanguage(link[0])" :to="link[1]" active-class="active" exact tabindex="-1" focusable="false" :hreflang="getCorrectLangcode(link[0])">
          <span :lang="getCorrectLangcode(link[0])" @click="closeNavigation">{{ $t('language.' + link[0]) }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { navigation } from '@/components/mixins/navigationMixin.js'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev, navigation],
  data () {
    return {
      sortingLanguagesOrder: ['pl', 'en', 'uk', 'pt-pt', 'kr', 'jp', 'zhs', 'zht', 'tr', 'ru']
    }
  },
  computed: {
    translations: function () {
      const trans = this.$store.getters.getTranslations
      let index = Object.keys(trans).map((key) => [key, trans[key]])

      index = index.sort((a, b) => {
        return this.sortingLanguagesOrder.indexOf(a[0]) - this.sortingLanguagesOrder.indexOf(b[0])
      })

      return index
    }
  },
  methods: {
    switchLanguage: function (language) {
      // Some languages have custom prefixes, which determinate translation file, etc.
      language = this.getCorrectLangprefix(language)
      this.$i18n.set(language)
    },
    getCorrectLangcode: function (langcode) {
      const prefixesToLangcodes = this.customLanguagesPrefixes()
      if (prefixesToLangcodes[langcode] !== undefined) {
        return prefixesToLangcodes[langcode]
      }
      return langcode
    },
    getCorrectLangprefix: function (langcode) {
      let prefix = langcode
      const prefixesToLangcodes = this.customLanguagesPrefixes()
      const itemIndex = Object.values(prefixesToLangcodes).indexOf(langcode)

      if (itemIndex > 0) {
        prefix = Object.keys(prefixesToLangcodes)[itemIndex]
      }
      return prefix
    },
    customLanguagesPrefixes: function () {
      return { jp: 'ja', zhs: 'zh-hans', zht: 'zh-hant', ua: 'uk', pt: 'pt-pt', kr: 'ko' }
    }

  }
}
</script>
