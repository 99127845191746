import { localeMapperMoment } from '@/helpers/localeMapperMoment.js'
import { useI18nPlugin } from '@unify/vuex-i18n'

const eventDate = function (date) {
  const i18n = useI18nPlugin()
  const locale = i18n.locale()
  const localeMapped = localeMapperMoment(locale)

  moment.locale(localeMapped)
  const startDate = moment(date.value)
  const endDate = moment(date.end_value)
  if (date.value && date.end_value && date.end_value !== '0' && (startDate.format() !== endDate.format())) {
    if (startDate.format('YYYY') === endDate.format('YYYY')) {
      // same year
      switch (locale) {
        case 'pl':
        case 'ru': {
          return '<span class="start-date">' + startDate.format('D MMM') + '</span><span class="arrow">—</span><span class="end-date">' + endDate.format('D MMM YYYY') + '</span>'
        }
        default : {
          return '<span class="start-date">' + startDate.format('MMM Do') + '</span><span class="arrow">—</span><span class="end-date">' + endDate.format('MMM Do YYYY') + '</span>'
        }
      }
    } else {
      // different years
      switch (locale) {
        case 'pl':
        case 'ru': {
          return '<span class="start-date">' + startDate.format('D MMM YYYY') + '</span><span class="arrow">—</span><span class="end-date">' + endDate.format('D MMM YYYY') + '</span>'
        }
        default : {
          return '<span class="start-date">' + startDate.format('MMM Do YYYY') + '</span><span class="arrow">—</span><span class="end-date">' + endDate.format('MMM Do YYYY') + '</span>'
        }
      }
    }
  }
  if (date.value) {
    // only start date
    switch (locale) {
      case 'pl':
      case 'ru': {
        return moment(date.value).format('D MMM YYYY')
      }
      default : {
        return moment(date.value).format('MMM Do YYYY')
      }
    }
  }
}

export {
  eventDate
}
