<template>
  <div class="sub-menu" v-if="menu && menu.subMenu && menu.subMenu.menu_items" :key="'sub-menu-' + currentLanguage">
    <ul class="menu links">
      <template v-for="(menuElem, index) in menu.subMenu.menu_items" :key="'l-' + index">
        <li v-if="menuElem.external === false">
          <router-link :to="'/' + menuElem.path" active-class="active" :target="menuElem.target" :class="menuElem.class"
                       :rel="menuElem.rel">
            <span @click="closeNavigation">
              {{ menuElem.title }}
            </span>
          </router-link>
        </li>
        <li class="external" v-else>
          <a :href="menuElem.path" :target="menuElem.target" :class="menuElem.class" :rel="menuElem.rel" tabindex="-1"
             focusable="false">
            <span v-if="externalIsInternal(menuElem.path)" @click="closeNavigation">
              {{ menuElem.title }}
            </span>
            <span v-else>
              {{ menuElem.title }}
            </span>
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { menu } from '@/components/mixins/menuMixin.js'
import { navigation } from '@/components/mixins/navigationMixin.js'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev, menu, navigation],
  props: {
    menuName: {
      type: String,
      default: 'sub-menu'
    }
  },
  data () {
    return {
      menu: {
        data: {}
      }
    }
  }
}
</script>
