import Cookies from 'js-cookie'
import { logger } from '@/helpers/logger.js'

// Content types
const article = () => import('../components/pages/content-types/Article.vue')
const superArticle = () => import('../components/pages/content-types/SuperArticle.vue')
const gallery = () => import('../components/pages/content-types/Gallery.vue')
const venue = () => import('../components/pages/content-types/Venue.vue')
const video = () => import('../components/pages/content-types/Video.vue')
const event = () => import('../components/pages/content-types/Event.vue')
const work = () => import('../components/pages/content-types/Work.vue')
const artist = () => import('../components/pages/content-types/Artist.vue')
const staticPage = () => import('../components/pages/content-types/StaticPage.vue')
const advancedPage = () => import('../components/pages/content-types/AdvancedPage.vue')
const knowledgeBase = () => import('../components/pages/content-types/KnowledgeBase.vue')
const podcast = () => import('../components/pages/content-types/Podcast.vue')
const sitemap = () => import('../components/pages/content-types/Sitemap.vue')
const recipe = () => import('../components/pages/content-types/Recipe.vue')

// Views
const events = () => import('../components/pages/views/Events.vue')
const works = () => import('../components/pages/views/Works.vue')
const artists = () => import('../components/pages/views/Artists.vue')
const multimedia = () => import('../components/pages/views/Multimedia.vue')
const publications = () => import('../components/pages/views/Publications.vue')
const search = () => import('../components/pages/views/Search.vue')

// Taxonomy pages
const topic = () => import('../components/pages/taxonomies/Topic.vue')
const series = () => import('../components/pages/taxonomies/Series.vue')
const tag = () => import('../components/pages/taxonomies/Tag.vue')

// Users
const author = () => import('../components/pages/users/Author.vue')

export const getExternalRoute = function (from, to, lang, router, store, axios) {
  let path = ''
  let component = ''
  let uuid = ''
  let params = {}
  let paramsString = ''
  let hash = ''

  store.dispatch('turnOffMaintenance')

  if (to.hash) {
    hash = to.hash
  }
  if (to.query) {
    params = to.query
    let counter = 0
    for (const key in to.query) {
      if (Object.prototype.hasOwnProperty.call(to.query, key)) {
        counter++
        if (counter === 1) {
          paramsString += '?'
        }
        paramsString += key + '=' + to.query.date
      }
    }
  }
  const url = process.env.VUE_APP_ENDPOINT_URL + lang + '/api/routing/path?path=' + to.path + paramsString + getAccessToken()
  logger('Routing endpoint url:', url)
  let fetchRetryCount = 0
  let fetchTimeout = {}
  let retried = false
  const fetchData = function () {
    axios.get(url).then(response => {
      const data = response.data.data

      if (data && data.type !== 404) {
        logger('Routing endpoint:', data)
        // check if redirect is needed
        if (data.redirect === 1 && data.target !== 'external') {
          router.push(data.canonical).catch(() => {
            logger('Router push with redirect error: ' + data.canonical)
          })
        }

        if (data.redirect === 1 && data.target === 'external') {
          window.location.href = data.canonical
        }

        component = findComponent(data.type, data, lang, params)
        path = data.canonical
        // remove params from url
        path = path.split('?')[0]
        uuid = data.uuid

        let translations = {}
        if (data.language) {
          translations = data.language
        }

        let metadata = {}
        if (data.metadata) {
          metadata = data.metadata
        }

        if ((component && component.component) && (from.path !== path)) {
          const route = {
            path,
            query: to.query,
            component: component.component,
            meta: {
              type: component.name ? component.name : component.meta.type
            },
            props: {
              uuid,
              language: lang,
              translations,
              metadata,
              time: +new Date(),
              state: data.state ? data.state : ''
            }
          }
          if (component.props) {
            route.props = component.props
          }

          if (component.name === 'search') {
            const currentPath = path
            // get search text from url and replace %20 entity with spaces
            let searchText = currentPath.substr(currentPath.lastIndexOf('/') + 1)
            searchText = decodeURIComponent(searchText)
            const slashCount = currentPath.match(/\//g).length
            if (!((searchText === '' && slashCount === 3) || slashCount === 2)) {
              route.props.searchString = searchText
            }
          }

          if (data.version) {
            route.props.version = data.version
          }

          // all routes should be added without spaces
          path = path.replace(/ /g, '%20')
          router.addRoute(route)
          router.push({
            path,
            query: to.query,
            hash
          }).catch((error) => {
            logger('Router new route push error: ' + data.canonical, error)
          })
        } else {
          if ((component && component.component)) {
            logger('Route not found!', true)
          }

          if ((from.path === path)) {
            logger('Target route same as from route', true)
          }

          store.dispatch('turnOffPreloader')
        }
      } else {
        logger('Route not found!')
        router.push({
          path: data.canonical
        }).catch(() => {
          logger('Router push error: ' + data.canonical)
        })
      }
    }, response => {
      if (response.response && response.response.data && response.response.data.includes('maintenance-page')) {
        logger('Routing communication error. Maintenance detected')
        router.push(lang + '/maintenance')
        return
      }
      fetchRetryCount++
      if (fetchRetryCount <= process.env.VUE_APP_MAX_FETCH_RETRY_COUNT) {
        if (retried) {
          // if timeout was set already
          // clear it, before set new one
          clearTimeout(fetchTimeout)
        }
        retried = true
        logger('Routing communication error. Retry count: ' + fetchRetryCount)
        fetchTimeout = setTimeout(function () {
          fetchData()
        }, process.env.VUE_APP_FETCH_TIMEOUT)
      } else {
        if (retried) {
          clearTimeout(fetchTimeout)
        }
      }
      logger('Routing communication error', response)
    })
  }
  fetchData()
}

function getAccessToken () {
  const uat = Cookies.get('SESS_uat')
  if (uat !== undefined) {
    return '&token=' + uat
  } else {
    return ''
  }
}

function findComponent (type, data, lang, params) {
  switch (type) {
    case 'node': {
      switch (data.content_type) {
        case 'article': {
          return {
            name: 'article',
            component: article
          }
        }
        case 'superarticle': {
          return {
            name: 'superArticle',
            component: superArticle
          }
        }
        case 'gallery': {
          return {
            name: 'gallery',
            component: gallery
          }
        }
        case 'venue': {
          return {
            name: 'venue',
            component: venue
          }
        }
        case 'video': {
          return {
            name: 'video',
            component: video
          }
        }
        case 'event': {
          return {
            name: 'event',
            component: event
          }
        }
        case 'work': {
          return {
            name: 'work',
            component: work
          }
        }
        case 'artist': {
          return {
            name: 'artist',
            component: artist
          }
        }
        case 'static_page': {
          return {
            name: 'staticPage',
            component: staticPage
          }
        }
        case 'advanced_page': {
          return {
            name: 'advancedPage',
            component: advancedPage
          }
        }
        case 'knowledge_base': {
          return {
            name: 'knowledgeBase',
            component: knowledgeBase
          }
        }
        case 'podcast': {
          return {
            name: 'podcast',
            component: podcast
          }
        }
        case 'sitemap': {
          return {
            name: 'sitemap',
            component: sitemap
          }
        }
        case 'recipe': {
          return {
            name: 'recipe',
            component: recipe
          }
        }
      }
      break
    }
    case 'views': {
      switch (data.view_id) {
        case 'artists_list_api': {
          return {
            name: 'artists',
            component: artists
          }
        }
        case 'works_list_api': {
          return {
            name: 'works',
            component: works
          }
        }
        case 'multimedia_list_api': {
          return {
            name: 'multimedia',
            component: multimedia
          }
        }
        case 'events_list_api': {
          return {
            name: 'events',
            component: events,
            props: function () {
              let endpointParameterValueProp = ''
              if (params && params.date) {
                endpointParameterValueProp = params.date
              }
              return {
                uuid: data.uuid,
                language: lang,
                translations: data.language,
                time: +new Date(),
                endpointParameterValueProp,
                metadata: data.metadata
              }
            }
          }
        }
        case 'publications_list_api': {
          return {
            name: 'publications',
            component: publications
          }
        }
        case 'elastic_search': {
          return {
            name: 'search',
            component: search
          }
        }
      }
      break
    }
    case 'taxonomy_term': {
      switch (data.content_type) {
        case 'topics': {
          return {
            name: 'topic',
            component: topic
          }
        }
        case 'series': {
          return {
            name: 'series',
            component: series
          }
        }
        case 'tags': {
          return {
            name: 'tag',
            component: tag
          }
        }
      }
      break
    }
    case 'user': {
      switch (data.content_type) {
        case 'user': {
          return {
            name: 'author',
            component: author
          }
        }
      }
      break
    }
  }
}
