<template>
   <div class="contrast-switch" :class="greyLogo">
    <button
      id="contrast-toggle"
      class="contrast-toggle"
      @click.prevent="toogleContrast">{{ $t('header.contrast-switch.title') }}
    </button>
  </div>
</template>

<script>
import { highContrast } from '@/components/mixins/highContrastMixin.js'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev, highContrast],
  data () {
    return {
    }
  },
  methods: {
    toogleContrast: function () {
      if (this.highContrast === true) {
        document.body.classList.remove('high-contrast')
        this.$store.dispatch('turnOffHighContrast')
      } else {
        document.body.classList.add('high-contrast')
        this.$store.dispatch('turnOnHighContrast')
      }
    }
  },
  computed: {
    greyLogo: function () {
      if (this.$store.getters.getLogoAlternativeState) {
        return 'grey-version'
      } else {
        return false
      }
    }
  },
  mounted: function (done) {
    if (this.highContrast === true) {
      document.body.classList.add('high-contrast')
      this.$store.dispatch('turnOnHighContrast')
    } else {
      document.body.classList.remove('high-contrast')
      this.$store.dispatch('turnOffHighContrast')
    }
  }
}
</script>
