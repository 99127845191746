<template>
  <div class="sidebar sidebar-left" :key="currentLanguage" v-if="!maintenance">
    <div class="sidebar-inner">
      <p>Ⓒ {{ new Date().getFullYear() }} CULTURE.PL</p>
    </div>
  </div>
</template>

<script>
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev],
  computed: {
    currentLanguage: function () {
      return this.$i18n.locale()
    },
    maintenance: function () {
      return this.$store.getters.getMaintenanceState
    }
  }
}
</script>
