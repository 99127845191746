import { wrap } from './wrap'
import { resize } from './resize'
import { stickInParent } from './stickInParent'

const directives = {
  wrap,
  resize,
  stickInParent
}

export default directives
