<template>
  <Teleport to="body" v-if="visible">
    <div id="newsletter-popup" class="popup" aria-live="assertive" role="alert">
      <div class="popup-wrapper">
        <div class="popup-wrapper-inner">
          <div class="newsletter-form newsletter-form-popup">
            <div @click="toggleNewsletterPopup" class="close"></div>
            <component :is="'NewsletterForm'" :mode="'popup'"></component>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { mapGetters } from 'vuex'
import NewsletterForm from '@/components/forms/Newsletter.vue'
import { popup } from '@/components/mixins/popupMixin.js'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev, popup],
  data () {
    return {
      timeouts: [],
      previousActiveElement: {}
    }
  },
  computed: {
    ...mapGetters({
      updateTime: 'getUpdateTime'
    })
  },
  methods: {
    newsletterESCPressed () {
      if (this.visible) {
        this.closeNewsletterPopup()
      }
    },
    toggleNewsletterPopup (event) {
      const currentWidth = this.$store.getters.getWindowWidth
      const show = !this.visible

      if (show) {
        this.setScrollPosition()
        this.previousActiveElement = event.target
      } else {
        this.previousActiveElement.focus()
      }
      if (currentWidth >= 768) {
        event.preventDefault()
        this.visible = !this.visible
        document.body.classList.toggle('noscroll')
        document.body.classList.toggle('newsletter-popup-active')

        if (show) {
          window.history.pushState('forward', 'Popup', window.location.href + '#popup')
        } else {
          window.history.pushState('', document.title, window.location.pathname + window.location.search)
        }
      } else {
        const url = new URL(event.target.href)
        this.$router.push(url.pathname)
      }
    },
    closeNewsletterPopup () {
      this.visible = false
      document.body.classList.remove('noscroll')
      document.body.classList.remove('newsletter-popup-active')
      this.previousActiveElement.focus()
    }
  },
  mounted () {
    this.emitter.on('ESCPressed', this.newsletterESCPressed)
    $(window).on('popstate', function () {
      $('.close').click()
    })
  },
  beforeUnmount () {
    this.emitter.off('ESCPressed', this.newsletterESCPressed)
    const popup = document.getElementsByClassName('newsletter-popup')
    for (let i = 0; i < popup.length; i++) {
      popup[i].removeEventListener('click', event => this.toggleNewsletterPopup(event))
    }
    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i])
    }
  },
  watch: {
    'updateTime' () {
      const vm = this
      const timeout = setTimeout(function () {
        const popup = document.getElementsByClassName('newsletter-popup')
        for (let i = 0; i < popup.length; i++) {
          // Remove all event listeners
          popup[i].outerHTML = popup[i].outerHTML // eslint-disable-line no-self-assign
          // Add new listener
          popup[i].addEventListener('click', event => vm.toggleNewsletterPopup(event))
        }
      }, 300)
      this.timeouts.push(timeout)
    },
    visible (val) {
      if (val === true) {
        this.$store.dispatch('GTMHelperPushGAEvent', {
          category: 'popupOpen',
          action: 'open',
          label: 'Newsletter popup'
        })
      }
    }
  },
  components: {
    NewsletterForm
  }
}
</script>
