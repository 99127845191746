export const navigation = {
  methods: {
    closeNavigation: function () {
      const navigation = document.getElementById('navigation')
      const elem = $('#navigation .navigation-container')
      const navToggle = document.getElementById('nav-toggle')

      if (navigation.classList.contains('open')) {
        navToggle.classList.remove('open')
        navToggle.setAttribute('aria-expaned', 'false')
        navigation.classList.remove('open')
        document.documentElement.classList.remove('html--is-locked')
        elem.find('a:focus').blur()
        this.disableNavFocus()
      }
    },
    disableNavFocus () {
      const navigation = document.getElementById('navigation')

      if (navigation !== null) {
        const menuLinks = navigation.getElementsByTagName('a')

        if (menuLinks.length > 0) {
          Array.from(menuLinks).forEach(function (item) {
            item.setAttribute('tabindex', '-1')
            item.setAttribute('focusable', 'false')
          })
        }
      }
    }
  }
}
