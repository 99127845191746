<template>
  <main role="document" aria-relevant="all" aria-live="assertive">
    <div class="Cookie Cookie--top Cookie--base" v-if="this.$root.firstContentLoaded && !cookiesButtonClicked">
      <div class="Cookie__content">
        <div> {{ $t("cookies.info.text") }} </div>
      </div>
      <div class="Cookie__buttons">
        <button class="Cookie__button" type="button" @click="cookiesButtonClick">{{ $t("cookies.info.button") }}</button>
      </div>
    </div>
    <router-view :key="$route.fullPath"></router-view>
  </main>
</template>

<script>
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev],
  methods: {
    cookiesButtonClick: function () {
      this.$store.dispatch('setCookiesButtonClicked', true)
    }
  },
  computed: {
    cookiesButtonClicked: function () {
      return this.$store.getters.getCookiesButtonClicked
    }
  }
}
</script>
