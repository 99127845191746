import { localeMapperMoment } from '@/helpers/localeMapperMoment.js'
import { useI18nPlugin } from '@unify/vuex-i18n'

const nodeDate = function (value) {
  if (value) {
    const i18n = useI18nPlugin()
    const locale = i18n.locale()
    const localeMapped = localeMapperMoment(locale)
    moment.locale(localeMapped)
    switch (locale) {
      case 'pl':
      case 'ru': {
        return moment(value, 'YYYY-MM-DDTHH:mmZ').format('D MMM YYYY')
      }
      default : {
        return moment(value, 'YYYY-MM-DDTHH:mmZ').format('MMM D YYYY')
      }
    }
  }
}

export {
  nodeDate
}
