<template>
  <div id="navigation" class="navigation" @focusout="closeNavigationOnFocusOut">
    <div class="navigation-close-area" @click="closeNavigation"></div>
    <div class="navigation-container">
      <div class="nav-close">
        <a href="#" @click.prevent="closeNavigation" tabindex="-1" focusable="false" role="button">
          <img v-if="!highContrast" src="/static/img/icons/close_white.svg" title="" alt="" aria-hidden="true" />
          <img v-else src="/static/img/icons/close_hc.svg" title="" alt="" aria-hidden="true" />
          <span class="visually-hidden">{{ $t('header.navigation.nav-close.title') }}</span>
        </a>
      </div>
      <div class="content" tabindex="-1" focusable="false">
        <app-lang-switch></app-lang-switch>
        <app-main-menu></app-main-menu>
        <app-sub-menu></app-sub-menu>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import niceScroll from 'jquery.nicescroll'
/* eslint-enable no-unused-vars */
import MainMenu from '@/components/menus/MainMenu.vue'
import SubMenu from '@/components/menus/SubMenu.vue'
import LangSwitch from '@/components/header/LangSwitch.vue'
import { navigation } from '@/components/mixins/navigationMixin.js'
import { highContrast } from '@/components/mixins/highContrastMixin.js'
import { dev } from '@/components/mixins/devMixin.js'
import { menu } from '@/components/mixins/menuMixin'

export default {
  mixins: [dev, navigation, highContrast, menu],
  components: {
    appMainMenu: MainMenu,
    appSubMenu: SubMenu,
    appLangSwitch: LangSwitch
  },
  data () {
    return {
      timeouts: []
    }
  },
  methods: {
    ESCPressedNavigation () {
      const navigation = document.getElementById('navigation')
      const navToggle = document.getElementById('nav-toggle')

      if (navigation.classList.contains('open')) {
        navToggle.classList.remove('open')
        navToggle.setAttribute('aria-expaned', 'false')
        navigation.classList.remove('open')
        document.documentElement.classList.remove('html--is-locked')
        this.disableNavFocus()
      }
    },
    openNavigation: function () {
      const navigation = document.getElementById('navigation')
      const navToggle = document.getElementById('nav-toggle')

      if (!navigation.classList.contains('open')) {
        navToggle.classList.add('open')
        navToggle.setAttribute('aria-expaned', 'true')
        navigation.classList.add('open')
        document.documentElement.classList.add('html--is-locked')
        this.enableNavFocus()
      }
    },
    closeNavigationOnFocusOut: function () {
      const navigation = document.getElementById('navigation')
      const elem = $('#navigation .navigation-container')
      const navToggle = document.getElementById('nav-toggle')
      const self = this

      const timeout = setTimeout(function () {
        const hasFocus = (elem.find('a:focus').length > 0)

        if (!hasFocus) {
          if (navigation.classList.contains('open')) {
            navToggle.classList.remove('open')
            navToggle.setAttribute('aria-expaned', 'false')
            navigation.classList.remove('open')
            document.documentElement.classList.remove('html--is-locked')
            self.disableNavFocus()
          }
        }
      }, 10)
      this.timeouts.push(timeout)
    },
    createScroll () {
      $('.navigation .navigation-container > .content').niceScroll({
        cursorcolor: '#ffffff',
        cursoropacitymin: 0.25,
        cursoropacitymax: 0.25,
        autohidemode: false,
        cursorborderradius: 0,
        cursorwidth: 8,
        cursorborder: 'none',
        background: '#1e1e1e',
        grabcursorenabled: true,
        enablemousewheel: true,
        scrollbarid: 'nicescroll-navigation'
      })
    },
    destroyScroll () {
      $('.navigation .navigation-container > .content').getNiceScroll().remove()
    },
    disableNavFocus () {
      if (document.getElementById('navigation') !== null) {
        const menuLinks = document.getElementById('navigation').getElementsByTagName('a')

        if (menuLinks.length > 0) {
          Array.from(menuLinks).forEach(function (item) {
            item.setAttribute('tabindex', '-1')
            item.setAttribute('focusable', 'false')
          })
        }
      }
      this.focusNavToggle()
    },
    enableNavFocus () {
      if (document.getElementById('navigation') !== null) {
        const menuLinks = document.getElementById('navigation').getElementsByTagName('a')

        if (menuLinks.length > 0) {
          menuLinks.forEach(function (item) {
            item.setAttribute('tabindex', '0')
            item.setAttribute('focusable', 'true')
          })
        }
      }
    },
    focusNavToggle () {
      const timeout = setTimeout(function () {
        if (document.querySelector('header #nav-toggle')) {
          document.querySelector('header #nav-toggle').focus()
        }
      }, 100)
      this.timeouts.push(timeout)
    }
  },
  mounted: function () {
    this.emitter.on('ESCPressed', this.ESCPressedNavigation)
    this.createScroll()
  },
  beforeUnmount () {
    this.emitter.off('ESCPressed', this.ESCPressedNavigation)
    this.destroyScroll()
    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i])
    }
  },
  created: function () {
    this.$store.dispatch('SetMenu', this.$i18n.locale()).then(() => {
      this.fetchData()
    })
    this.localeWatcher = this.$store.watch((state) => state.i18n.locale, () => {
      this.$store.dispatch('SetMenu', this.$i18n.locale()).then(() => {
        this.fetchData()
      })
    })
  }
}
</script>
