export const lazyLoader = {
  methods: {
    getWidth: function () {
      return 0
    },
    getImageRatio: function () {
      return 0
    },
    containerStyles: function () {
      const ratio = this.getImageRatio()
      if (ratio > 0) {
        return {
          paddingBottom: ratio + '%',
          position: 'relative'
        }
      } else {
        return {}
      }
    },
    imageStyles: function () {
      const ratio = this.getImageRatio()
      if (ratio > 0) {
        return {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }
      } else {
        return {}
      }
    },
    isNumeric (n) {
      return !isNaN(parseFloat(n)) && isFinite(n)
    }
  }
}
