// Static pages
const e500 = () => import('@/components/pages/errors/500.vue')
const maintenance = () => import('@/components/pages/errors/maintenance.vue')
const contact = () => import('@/components/pages/others/Contact.vue')
const newsletter = () => import('@/components/pages/others/Newsletter.vue')
const polesWorldCulture = () => import('@/components/poles-world-culture/PolesWordCulture.vue')
const memory = () => import('@/components/games/memory/Memory.vue')

export const staticRoutes = [
  {
    path: '/',
    redirect: {
      path: '/'
    }
  },
  {
    path: '/jazzahead2023',
    redirect: {
      path: '/en/jazzahead2023'
    }
  },
  {
    path: '/GreenhouseSilentDisco',
    redirect: {
      path: '/en/article/greenhouse-silent-disco'
    }
  },
  {
    path: '/:language/500',
    component: e500,
    meta: {
      type: 'e500'
    }
  },
  {
    path: '/:language/maintenance',
    component: maintenance,
    meta: {
      type: 'maintenance'
    }
  },
  {
    path: '/pl/kontakt',
    component: contact,
    meta: {
      type: 'contact'
    }
  },
  {
    path: '/en/contact',
    component: contact,
    meta: {
      type: 'contact'
    }
  },
  {
    path: '/ru/kontakt',
    component: contact,
    meta: {
      type: 'contact'
    }
  },
  {
    path: '/ua/kontakt',
    component: contact,
    meta: {
      type: 'contact'
    }
  },
  {
    path: '/:language/newsletter',
    component: newsletter,
    meta: {
      type: 'newsletter'
    }
  },
  {
    path: '/pl/sladami-polakow',
    component: polesWorldCulture,
    meta: {
      type: 'polesWorldCulture'
    }
  },
  {
    path: '/en/poles-far-and-wide',
    component: polesWorldCulture,
    meta: {
      type: 'polesWorldCulture'
    }
  },
  {
    path: '/ru/sledami-poljakov',
    component: polesWorldCulture,
    meta: {
      type: 'polesWorldCulture'
    }
  },
  {
    path: '/pl/gry/memory',
    component: memory,
    meta: {
      type: 'games'
    },
    redirect: {
      path: '/en/games/memory'
    }
  },
  {
    path: '/en/games/memory',
    component: memory,
    meta: {
      type: 'games'
    }
  }
]
