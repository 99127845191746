<template>
  <div class="nav-switch" :class="greyLogo">
    <button
      aria-controls="navigation"
      class="nav-toggle"
      id="nav-toggle"
      @click.prevent="openNavigation"
      @keyup.enter="openNavigation">{{ $t('header.nav-toggle.title') }}
    </button>
  </div>
</template>

<script>
import { highContrast } from '@/components/mixins/highContrastMixin.js'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev, highContrast],
  methods: {
    openNavigation: function () {
      const navigation = document.getElementById('navigation')
      const navToggle = document.getElementById('nav-toggle')

      if (!navigation.classList.contains('open')) {
        navToggle.classList.add('open')
        navToggle.setAttribute('aria-expaned', 'true')
        navigation.classList.add('open')
        document.documentElement.classList.add('html--is-locked')
        this.enableNavFocus()
        // Fix wrong internal scroll height on menu open
        $('.navigation .navigation-container > .content').getNiceScroll().resize()
      }
    },
    enableNavFocus () {
      const navigation = document.getElementById('navigation')

      if (navigation !== null) {
        const menuLinks = navigation.getElementsByTagName('a')

        if (menuLinks.length > 0) {
          Array.from(menuLinks).forEach(function (item) {
            item.setAttribute('tabindex', '0')
            item.setAttribute('focusable', 'true')
          })
        }
      }
    }
  },
  computed: {
    greyLogo: function () {
      if (this.$store.getters.getLogoAlternativeState) {
        return 'grey-version'
      } else {
        return false
      }
    }
  }
}
</script>
