<template>
  <div class="header-text" v-html="html">
  </div>
</template>

<script>
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev],
  props: {
    html: {}
  }
}
</script>
