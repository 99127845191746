import { localeMapperMoment } from '@/helpers/localeMapperMoment.js'
import { useI18nPlugin } from '@unify/vuex-i18n'

const artistDate = function (date) {
  const i18n = useI18nPlugin()
  const locale = i18n.locale()
  const localeMapped = localeMapperMoment(locale)

  moment.locale(localeMapped)
  const startDate = moment(date.value)
  if (date.value && date.end_value && date.end_value !== '0') {
    // birth and dead date
    const endDate = moment(date.end_value)
    switch (locale) {
      case 'pl':
      case 'ru': {
        return startDate.format('D.MM.YYYY') + '<span>—</span>' + endDate.format('D.MM.YYYY')
      }
      default : {
        return startDate.format('D.MM.YYYY') + '<span>—</span>' + endDate.format('D.MM.YYYY')
      }
    }
  }
  if (date.value) {
    // only birth date
    switch (locale) {
      case 'pl':
      case 'ru': {
        return moment(date.value).format('D.MM.YYYY')
      }
      default : {
        return moment(date.value).format('D.MM.YYYY')
      }
    }
  }
}

export {
  artistDate
}
