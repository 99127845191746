<template>
  <div v-if="menu && menu.footer && menu.footer.menu_items" :key="'footer-menu-' + currentLanguage" class="footer-menu">
    <ul class="menu">
      <template v-for="(menuElem, index) in menu.footer.menu_items" :key="'l-' + index">
        <li v-if="menuElem.external === false">
          <router-link :event="menuElem.class ? '' : 'click'"
                       :to="'/' + menuElem.path" active-class="active" :class="menuElem.class" :rel="menuElem.rel"
                       :target="menuElem.target">
            {{ menuElem.title }}
          </router-link>
        </li>
        <li v-else class="external"><a :class="menuElem.class" :href="menuElem.path"
                                                           :rel="menuElem.rel"
                                                           :target="menuElem.target">{{ menuElem.title }}</a></li>
      </template>
    </ul>
  </div>
</template>

<script>
import { menu } from '@/components/mixins/menuMixin.js'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev, menu],
  props: {
    menuName: {
      type: String,
      default: 'footer'
    }
  },
  data () {
    return {
      menu: {}
    }
  }
}
</script>
