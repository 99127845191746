<template>
  <div class="skip-links">
    <ul class="links-list">
      <li>
        <a href="#nav-toggle" @click.prevent="openNavigation" @keyup.enter="openNavigation">
          {{ $t('header.skip-links.menu') }}
        </a>
      </li>
      <li>
        <a href="#search-toggle" @click.prevent="openSearchBar" @keyup.enter="openSearchBar">
          {{ $t('header.skip-links.search') }}
        </a>
      </li>
      <li>
        <a href="#main-content" @click.prevent="gotoToContent" @keyup.enter="gotoToContent">
          {{ $t('header.skip-links.content') }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev],
  data () {
    return {
      timeouts: []
    }
  },
  methods: {
    openNavigation: function () {
      if (!document.getElementById('navigation').classList.contains('open')) {
        document.getElementById('nav-toggle').classList.add('open')
        document.getElementById('navigation').classList.add('open')
        document.documentElement.classList.add('html--is-locked')
        this.enableNavFocus()
        const timeout = setTimeout(function () {
          if (document.querySelector('header #navigation a')) {
            document.querySelector('header #navigation a').focus()
          }
        }, 100)
        this.timeouts.push(timeout)
      }
    },
    enableNavFocus: function () {
      if (document.getElementById('navigation') !== null) {
        const menuLinks = document.getElementById('navigation').getElementsByTagName('a')
        if (menuLinks.length > 0) {
          Array.from(menuLinks).forEach(function (item) {
            item.setAttribute('tabindex', '0')
            item.setAttribute('focusable', 'true')
          })
        }
      }
    },
    openSearchBar: function () {
      document.body.classList.add('search-open')
      this.$store.dispatch('turnOnSearchBar')
      const timeout = setTimeout(function () {
        if (document.querySelector('header .search-bar input')) {
          document.querySelector('header .search-bar input').focus()
        }
      }, 100)
      this.timeouts.push(timeout)
    },
    gotoToContent: function () {
      const timeout = setTimeout(function () {
        if (document.querySelector('#main-content a')) {
          document.querySelector('#main-content a').focus()
        }
      }, 100)
      this.timeouts.push(timeout)
    }
  },
  beforeUnmount () {
    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i])
    }
  }
}
</script>
