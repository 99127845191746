import { localeMapperMoment } from '@/helpers/localeMapperMoment.js'
import { useI18nPlugin } from '@unify/vuex-i18n'

const eventsDate = function (date) {
  const i18n = useI18nPlugin()
  const locale = i18n.locale()
  const localeMapped = localeMapperMoment(locale)
  moment.locale(localeMapped)
  const dataProcessor = moment(date)
  dataProcessor.locale(locale)
  return dataProcessor.format('MMMM YYYY')
}

export {
  eventsDate
}
