import { artistDate } from './artistDate'
import { artistYearOnly } from './artistYearOnly'
import { eventDate } from './eventDate'
import { eventsDate } from './eventsDate'
import { nodeDate } from './nodeDate'
import { yearDate } from './yearDate'
import { truncate } from './truncate'
import { stripTags } from './stripTags'

const filters = {
  artistDate,
  artistYearOnly,
  eventDate,
  eventsDate,
  nodeDate,
  yearDate,
  truncate,
  stripTags
}

export default filters
