<template>
  <div class="search-switch">
    <button
      id="search-toggle"
      class="search-toggle"
      @click.prevent="toggleSearchBar">{{ $t('header.search.title') }}
    </button>
  </div>
</template>

<script>
import { highContrast } from '@/components/mixins/highContrastMixin.js'
import { searchBar } from '@/components/mixins/searchBarMixin.js'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev, highContrast, searchBar],
  data () {
    return {
      timeouts: []
    }
  },
  methods: {
    toggleSearchBar: function () {
      if (this.searchBar === true) {
        document.body.classList.remove('search-open')
        this.$store.dispatch('turnOffSearchBar')
      } else {
        document.body.classList.add('search-open')
        this.$store.dispatch('turnOnSearchBar')
        const timeout = setTimeout(function () {
          if (document.querySelector('header .search-bar input')) {
            document.querySelector('header .search-bar input').focus()
          }
        }, 100)
        this.timeouts.push(timeout)
      }
    }
  },
  beforeUnmount () {
    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i])
    }
  }
}
</script>
