export const chinaVersion = {
  computed: {
    isChinaVersion: function () {
      if (process.env.VUE_APP_CHINA_VERSION === 'true') {
        return true
      }

      return false
    }
  }
}
